import React from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import styles from './message.css'

const Message = (props) => {

  return (<Container className={`${styles.bg} py-3 bg-light`} fluid>
    <Row className="justify-content-center">
      <Col className="col-md-6 col-sm-12">
        <Card className="w-100 rounded-0">
          <CardBody>
            <h3 className="text-center font-weight-light m-0 text-uppercase">{props.message}</h3>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>)
}

export default Message