import api from '../api'

/** List students */
const listStudents = async (page, search) => {
  if (search) {
    const students = page ? await api.get(`/student?sort=name&sortTo=1&page=${page}&search=${search}`) : await api.get(`/student?sort=name&sortTo=1&search=${search}`)
    return students.data
  } else {
    const students = page ? await api.get(`/student?sort=name&sortTo=1&page=${page}`) : await api.get('/student?sort=name&sortTo=1')
    return students.data
  }
}

/** load student */
const getStudent = async (id) => {
  const students = await api.get(`/student/${id}`)
  return students.data
}

/** Add students */
const addStudents = async (data, id) => {
  const students = id ? await api.put(`/student/edit/${id}`, data) : await api.post('/student/add', data)
  return students.data
}

/** Add students */
const deleteStudents = async (id) => {
  const students = await api.delete(`/student/${id}`)
  return students.data
}

/** Total sells per date */
const totalCreditsDate = async (student, start, end) => {
  const sells = await api.get(`/credit-date?dateStart=${start}&dateEnd=${end}&student=${student}`)
  return sells.data
}

export {
  listStudents,
  getStudent,
  addStudents,
  deleteStudents,
  totalCreditsDate
}