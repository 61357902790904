import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Table, Button } from 'reactstrap'
import styles from './pdv.css'

/** Helpers */
import priceAjustFormat from '../../helpers/money'
import { dateTimeNow } from '../../helpers/date'

/** Controllers */
import { calculateChangeValue, calculateDeficitValue, payWithMoney, payWithCredits, addProductToCart, validatePayment, selectStudent } from '../../controllers/pdv'
import { schoolData } from '../../controllers/schools'
import { logoutUser } from '../../controllers/login'
import { listCategories } from '../../controllers/categories'
import { listProductsPdv } from '../../controllers/products'

/** Component */
import CartItem from './cart-item'
import Validate from './validate'
import Student from './student'
import Payment from './payment'

const Pdv = (props) => {
  /** On did mount */
  useEffect(() => {
    schoolData().then(schoolData => {
      setHeader({ title: schoolData.name, date: dateTimeNow() })
      document.title = `${schoolData.name} - PDV`
    })
    /** Load categories */
    listCategories().then(data => {
      setCategories(data.data)
    })
  }, [])

  /** States */
  const [processing, setProcessing] = useState(false)
  const [header, setHeader] = useState(null)
  const [student, setStudent] = useState(null)
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState(null)
  const [products, setProducts] = useState([])
  const [cart, setCart] = useState({ item: [], sum: 0, pay: { credits: 0, money: 0 } })
  const [modalPayment, setModalPayment] = useState(false)
  const [modalStudent, setModalStudent] = useState(true)
  const [modalPassword, setModalPassword] = useState(false)
  const [alertHtml, setAlertHtml] = useState(null)

  /** Set student */
  const setStudentHandler = () => {
    const register = document.querySelector('#register-input').value // Get value from input
    selectStudent(register).then((student) => {
      if (student.success) {
        document.querySelector('#register-input').disabled = true
        setStudent(student.data) // Set student to state
        setModalStudent(false) // Hide modal
        setAlertHtml(null) // Hide error message
      } else {
        document.querySelector('#register-input').disabled = false
        setAlertHtml('Matricula não localizada, tente novamente.') // Show error message
        document.querySelector('#register-input').select() // Focus input
      }
    })
  }

  /** Select category */
  const selectCategoryHandler = (category) => {
    setSelectedCategories({ name: category.name, id: category._id }) // Set selected category
    /** List products of selected category */
    listProductsPdv(category._id).then(data => {
      setProducts(data.data)
    })
  }

  /** Add product to cart */
  const selectProductHandler = (product) => {
    setCart(addProductToCart(product, cart)) // Add product to cart
  }

  /** Cancel sell */
  const cencelSellHandler = () => {
    /** Ask to confirm */
    if (window.confirm('Confirmar cancelamento?')) {
      setCart({ item: [], sum: 0, pay: { credits: 0, money: 0 } }) // Reset cart state
      setStudent(null) // Reset student state
      setModalStudent(true) // Show modal to set student
    }
  }

  /** Finish sell */
  const finishSellHandler = () => {
    setCart({ ...cart, pay: { credits: 0, money: 0 } }) // Reset cart payment
    setModalPayment(!modalPayment) // Show modal to pay
    calculateChangeValue(cart.pay.money, cart.pay.credits, cart.sum) // Verify values to change
    calculateDeficitValue(cart.pay.money, cart.pay.credits, cart.sum) // Verify values of payment deficit
  }

  /** Validate money */
  const payWithMoneyHandler = (event) => {
    setCart({ ...cart, pay: { credits: cart.pay.credits, money: payWithMoney(event) } }) // Updade money pay value
    calculateChangeValue(cart.pay.money, cart.pay.credits, cart.sum) // Verify values to change
    calculateDeficitValue(cart.pay.money, cart.pay.credits, cart.sum) // Verify values of payment deficit
  }

  /** Validate credits */
  const payWithCreditsHandler = (event) => {
    setCart({ ...cart, pay: { money: cart.pay.money, credits: payWithCredits(event, student.credit, cart.sum) } })// Updade credit pay value
    calculateChangeValue(cart.pay.money, cart.pay.credits, cart.sum) // Verify values to change
    calculateDeficitValue(cart.pay.money, cart.pay.credits, cart.sum) // Verify values of payment deficit
  }

  /** Validade with card */
  const validadeCard = (password) => {
    const { registration, name } = student
    /* Validate with custom code
    const firstNumbers = registration.toString().substring(0, 2)
    const lastNumbers = registration.toString().substr(registration.toString().length - 4)
    const characters = name.split(' ')
    let firstCharacter = characters[0]
    let lastCharacter = characters[(characters.length - 1)]
    firstCharacter = firstCharacter[0].toUpperCase()
    lastCharacter = lastCharacter[0].toUpperCase()
    const code = (firstNumbers + lastCharacter + lastNumbers + firstCharacter).toUpperCase()
    if (password === code) return true
    */
   if (password === registration.toString()) return true
    return false
  }

  /** Pay sell */
  const paySellHandler = (needPassword) => {
    document.querySelector('#pay-button').disabled = true
    let password = null
    if (needPassword) {
      password = document.querySelector('#password-input').value
      setProcessing(true)
    }
    validatePayment(cart, student._id, password, validadeCard(password)).then(sellData => {
      if (sellData.success) {
        setCart({ item: [], sum: 0, pay: { credits: 0, money: 0 } }) // Reset cart state
        setModalPayment(false) // Hide modal payment
        setModalPassword(false) // Hide modal password
        setStudent(null) // Reset student state
        setModalStudent(true) // Show modal to set student
        setProcessing(false)
        printSell(sellData.data)
      } else {
        alert('Senha incorreta!')
        setProcessing(false)
        password = document.querySelector('#password-input').value = ''
        password = document.querySelector('#password-input').focus()
        setModalPassword(true)
      }
    })
  }

  /** Exit handler  */
  const exitHandler = () => {
    if (logoutUser('pdv')) {
      props.history.push(`/${props.match.params.school}/admin/login`)
    } else {
      props.history.push(`/${props.match.params.school}/admin`)
    }
  }

  /** Print invoice */
  const printSell = (data) => {
    let itens = ''
    data.itens.forEach(item => {
      itens += `
        <tr>
          <td width="40%">${item.name}&nbsp;&nbsp;&nbsp;&#9;</td>
          <td width="20%" align="center">&#9;&nbsp;&nbsp;&nbsp;${item.quantity}&nbsp;&nbsp;&nbsp;&#9;</td>
          <td width="40%" align="right">&#9;&nbsp;&nbsp;&nbsp;${priceAjustFormat(item.price)}&nbsp;&nbsp;&nbsp;&#9;</td>
          <td width="40%" align="right">&#9;&nbsp;&nbsp;&nbsp;${priceAjustFormat(item.quantity * item.price)}</td>
        </tr>`
    })
    const html =
      `<table width="100%" style="text-transform: uppercase; font-family: monospace">
        <tr><td colspan="3" align="center">${document.title}</td></tr>
        <tr><td colspan="3" align="center">Venda: ${data._id}</td></tr>
        <tr><td colspan="3" align="center">Nome: ${student.name}</td></tr>
        <tr><td colspan="3" align="center">Data: ${dateTimeNow()}</td></tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
        <tr>
          <td>Item:&nbsp;&nbsp;&nbsp;&#9;</td>
          <td align="center">&#9;&nbsp;&nbsp;&nbsp;Qnt.:&nbsp;&nbsp;&nbsp;&#9;</td>
          <td align="right">&#9;&nbsp;&nbsp;&nbsp;Preço:&nbsp;&nbsp;&nbsp;&#9;</td>
          <td align="right">&#9;&nbsp;&nbsp;&nbsp;Total:</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black;"></td>
        </tr>
        ${itens}
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
        <tr>
        <td>Total</td>
        <td colspan="2" align="right">${priceAjustFormat(data.total)}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
        <tr>
          <td>Pago em credito:</td>
          <td colspan="2" align="right">${priceAjustFormat(data.payment.credits)}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black;"></td>
        </tr>
        <tr>
          <td>Pago em dinheiro:</td>
          <td colspan="2" align="right">${priceAjustFormat(data.payment.money)}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black;"></td>
        </tr>
        <tr>
          <td>Troco:</td>
          <td colspan="2" align="right">${priceAjustFormat((data.payment.money + data.payment.credits) - data.total)}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
        <tr>
          <td>Saldo em credito:</td>
          <td colspan="2" align="right">${priceAjustFormat(student.credit - data.payment.credits)}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
      </table>`
    const mywindow = window.open('', 'PRINT', 'height=400,width=600')
    mywindow.document.write(html)
    mywindow.document.close() // necessary for IE >= 10
    mywindow.focus() // necessary for IE >= 10*/
    mywindow.print()
    mywindow.close()
  }

  return (<Container className={`${styles.bg} py-3 bg-light`} fluid>
    {header ? (
      <Row>
        <Col className="text-center text-uppercase">
          <Card className="rounded-0">
            <CardBody>
              <Row>
                <Col className={`${styles.title} text-left text-truncate`}><h3 className="font-weight-light text-uppercase m-0">{header.title}</h3></Col>
                <Col className="text-right"><h6 className="font-weight-light text-uppercase m-0">{student ? <div>{`Nome: ${student.name} (${student.registration})`}</div> : null}Data: {header.date}</h6></Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    ) : null}
    <Row className={`${styles.middle} py-3`}>
      <Col className={`${styles['middle-card']} col-12 col-md-8`}>
        <Card className="w-100 rounded-0">
          <CardHeader className="text-center text-uppercase">Produtos {selectedCategories ? selectedCategories.name : '- selecione uma categoria'}</CardHeader>
          <CardBody className={styles['item-container']}>
            <Row className={`${styles['item-row']} w-100 mx-auto py-2 px-4`}>
              {products.length ? products.map((product) => (
                <CartItem key={product._id} name={product.name} price={priceAjustFormat(product.price)} action={() => selectProductHandler(product)} />
              )) : <Col className="text-center text-uppercase p-2">Nenhum item.<br />Selecione uma categoria.</Col>}
            </Row>
          </CardBody>
          <CardFooter>
            <Row className="justify-content-md-center text-nowrap">
              {categories.length ? categories.map((category) => (
                <Col className="col p-2" key={category._id}>
                  <Button className="rounded-0 text-uppercase btn-block" outline={selectedCategories === category._id ? false : true} color="secondary" onClick={() => selectCategoryHandler(category)}>{category.name}</Button>
                </Col>
              )) : <Col className="col-12 text-center text-uppercase p-2">Nenhuma categoria cadastrada.</Col>}
            </Row>
          </CardFooter>
        </Card>
      </Col>
      <Col className={`${styles['middle-card']} mt-3 mt-sm-0 col-12 col-md-4`}>
        <Card className="w-100 rounded-0">
          <CardHeader className="text-center text-uppercase">Pedido</CardHeader>
          <CardBody className={`${styles['item-container']} p-0`}>
            <Table className={styles['item-row']} size="sm">
              <tbody>
                {cart.item.length ? cart.item.map((item, index) => (
                  <tr key={item.id} className={`${styles['sell-list']} text-uppercase`}>
                    <td className="col-auto text-center font-weight-bold">{index + 1}</td>
                    <td className="col text-left font-weight-bold">{item.name}</td>
                    <td className="col-auto text-center font-weight-bold text-nowrap">{`(${item.quantity} x ${priceAjustFormat(item.price)})`}</td>
                    <td className="col-auto text-right font-weight-bold text-nowrap">{priceAjustFormat(item.quantity * item.price)}</td>
                  </tr>
                )) : (
                    <tr className="text-uppercase">
                      <td className="text-center text-nowrap">Nenhum item adicionado.</td>
                    </tr>
                  )}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <h4 className="text-uppercase font-weight-light text-right m-0">{`Total: ${priceAjustFormat(cart.sum)}`}</h4>
          </CardFooter>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col className="com-12 col-md-auto">
        <Button className="rounded-0 text-uppercase btn-block" color="danger" onClick={() => exitHandler()}>Sair do pdv</Button>
      </Col>
      <Col className="col-12 ml-auto col-md-auto">
        <Button className="rounded-0 text-uppercase btn-block" color="warning" onClick={() => cencelSellHandler()}>Cancelar venda</Button>
      </Col>
      <Col className="col col-md-4">
        <Button className="rounded-0 text-uppercase btn-block" color="success" onClick={() => finishSellHandler()} disabled={cart.item.length ? false : true}>Finalizar venda</Button>
      </Col>
    </Row>
    <Student modalStudent={modalStudent} alertHtml={alertHtml} setStudentHandler={setStudentHandler} exitHandler={exitHandler} />
    <Payment modalPayment={modalPayment} finishSellHandler={finishSellHandler} priceAjustFormat={priceAjustFormat} student={student} cart={cart} payWithCreditsHandler={payWithCreditsHandler} payWithMoneyHandler={payWithMoneyHandler} calculateDeficitValue={calculateDeficitValue} calculateChangeValue={calculateChangeValue} setModalPassword={setModalPassword} paySellHandler={paySellHandler} />
    <Validate processing={processing} modalPassword={modalPassword} paySellHandler={paySellHandler} setModalPassword={setModalPassword} />
  </Container>)
}

export default Pdv