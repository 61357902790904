import api from '../api'

/** School data */
const schoolData = async () => {
  const fullPath = window.location.pathname
  const parts = fullPath.split('/')
  const school = await api.get(`/school/${parts[1]}`)
  return school.data
}

/** List schools */
const listSchool = async (page) => {
  const sells = page ? await api.get(`/school?sort=name&sortTo=1&page=${page}`) : await api.get('/school?sort=name&sortTo=1')
  return sells.data
}

/** Add school */
const addschool = async (data, id) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const school = id ? await api.put(`/school/${id}`, data, config) : await api.post('/school', data, config)
  return school.data
}

/** Add products */
const deleteSchools = async (id) => {
  const school = await api.delete(`/school/${id}d3`)
  return school.data
}

export {
  schoolData,
  addschool,
  listSchool,
  deleteSchools
}