import React from 'react'
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const ModalForm = (props) => (
  <Modal isOpen={props.modalForm.show} onClosed={() => props.setAlertHtml(null)} centered>
    <form onSubmit={(event) => props.submit(event, props.modalForm.form, props.modalForm.data)}>
      <ModalHeader><span className="text-uppercase font-weight-light">{props.modalForm.title}</span></ModalHeader>
      <ModalBody>
        {props.alertHtml ? <Alert color="danger" className="rounded-0">{props.alertHtml}</Alert> : null}
        {props.children}
      </ModalBody>
      <ModalFooter>
        <Button type="submit" className="rounded-0 text-uppercase" disabled={props.loading ? true : false} color="primary">{props.modalForm.data ? 'Salvar' : 'Adicionar'}</Button>
        <Button className="rounded-0 text-uppercase" color="secondary" onClick={() => props.setModalForm({ ...props.modalForm, show: false })}>Cancelar</Button>
      </ModalFooter>
    </form>
  </Modal>
)

export default ModalForm