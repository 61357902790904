import React from 'react'
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Col, FormGroup, Input, Button } from 'reactstrap'

const PdvStudent = (props) => (
  <Modal isOpen={props.modalStudent} onOpened={() => document.querySelector('#register-input').focus()} centered>
    <ModalHeader><span className="text-uppercase font-weight-light">Identificação da pessoa</span></ModalHeader>
    <ModalBody>
      {props.alertHtml ? <Alert color="danger" className="rounded-0">{props.alertHtml}</Alert> : null}
      <FormGroup className="m-0" row>
        <Col>
          <Input id="register-input" className="rounded-0" type="number" min="0" placeholder="Número da matrícula" onKeyUp={(event) => { if (event.keyCode === 13) { props.setStudentHandler() } }} />
        </Col>
      </FormGroup>
    </ModalBody>
    <ModalFooter>
      <Button className="rounded-0 text-uppercase" color="secondary" onClick={() => props.setStudentHandler()}>Validar</Button>
      <Button className="rounded-0 text-uppercase" color="danger" onClick={() => props.exitHandler()}>Sair do PDV</Button>
    </ModalFooter>
  </Modal>
)

export default PdvStudent