import React, { useState, useEffect } from 'react'
import { Container, Navbar, NavbarBrand, Nav, NavItem, Button, Row, Col, Table, Card, CardBody, CardHeader, CardFooter, FormGroup, Input, Alert } from 'reactstrap'
import styles from './student.css'

/** Components */
import Pagination from './pagination'
import Loading from './loading'
import ModalForm from './form'

/** Helpers */
import priceAjustFormat from '../../helpers/money'
import { dateTime } from '../../helpers/date'

/** Controllers */
import { schoolData } from '../../controllers/schools'
import { logoutStudent } from '../../controllers/login'
import { getStudent } from '../../controllers/students'
import { listSellsStudent } from '../../controllers/sells'
import { listCredits, addCredits, getPagSeguroToken, getAssasPix, asaasVerification } from '../../controllers/credits'

const Home = (props) => {

  /** Load student data */
  const loadStudentData = () => {
    setLoading(true)
    schoolData().then(schoolDataValue => {
      setSchool(schoolDataValue)
      const id = JSON.parse(localStorage.getItem(`SNACKNEWS_LOGIN:${getDomain()}`)).user
      getStudent(id).then(studentDataValue => {
        setStudent(studentDataValue.data)
      })
      listSellsStudent(id, 1).then(sellsData => {
        setSells(sellsData)
        setLoading(false)
        setPaginationSells({ pages: sellsData.pagination, active: sellsData.page })
      })
      listCredits(id, 1).then(creditsData => {
        setCredits(creditsData)
        setLoading(false)
        setPaginationCredits({ pages: creditsData.pagination, active: creditsData.page })
      })
    })
  }

  /** On did mount */
  useEffect(() => {
    loadStudentData()
  }, [])

  /** States */
  const [school, setSchool] = useState(null)
  const [student, setStudent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [paginationSells, setPaginationSells] = useState({ pages: [1], active: 1 })
  const [paginationCredits, setPaginationCredits] = useState({ pages: [1], active: 1 })
  const [sells, setSells] = useState({ data: [] })
  const [credits, setCredits] = useState({ data: [] })
  const [modalPagSeguroForm, setModalPagSeguroForm] = useState({ show: false, title: '', form: '', data: null })
  const [modalAsaasForm, setModalAsaasForm] = useState({ show: false, title: '', form: '', data: null })
  const [pixData, setPixData] = useState(null)
  const [alertHtml, setAlertHtml] = useState(null)
  const [verifyPaymentButton, setVerifyPaymentButton] = useState(false)

  /** Get domain data */
const getDomain = () => {
  const fullPath = window.location.pathname
  const parts = fullPath.split('/')
  return parts[1]
}

  /** Go page pagination sells */
  const goPageSellsHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listSellsStudent(student._id, page).then(sellsData => {
      setSells(sellsData)
      setLoading(false)
      setPaginationSells({ pages: sellsData.pagination, active: sellsData.page })
    })
  }

  /** Go page pagination credits */
  const goPageCreditsHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listCredits(student._id, page).then(creditsData => {
      setCredits(creditsData)
      setLoading(false)
      setPaginationCredits({ pages: creditsData.pagination, active: creditsData.page })
    })
  }

  /** Add credits PagSeguro */
  const addCreditsPagSeguroHandler = (event) => {
    event.preventDefault()
    if (!event.target.credits.value) {
      setAlertHtml('O valor precisa ser informado.')
    } else {
      const value = parseFloat(event.target.credits.value.replace(',', '.')).toFixed(2)
      getPagSeguroToken({ ...school, value }).then(data => {
        const checkoutCode = data.checkout ? data.checkout.code._text : null
        if (checkoutCode) {
          window.PagSeguroLightbox({
            code: checkoutCode
          }, {
              success: transactionCode => {
                const id = JSON.parse(localStorage.getItem(`SNACKNEWS_LOGIN:${getDomain()}`)).user
                addCredits({value, gateway: 'pagseguro', transaction: transactionCode, status: 'pending', _student: id})
                .then(response => {
                  if(response.success) {
                    loadStudentData()
                    setModalPagSeguroForm({ ...modalPagSeguroForm, data: null, show: false })
                  } else {
                    setAlertHtml('Erro ao adicionar créditos.')
                  }
                })
              }
            })
        }
      })
    }
  }

  /** Add credits Asaas */
  const addCreditsAsaasHandler = (event) => {
    event.preventDefault()
    if (!event.target.credits.value) {
      setAlertHtml('O valor precisa ser informado.')
    } else {
      const value = parseFloat(event.target.credits.value.replace(',', '.')).toFixed(2)
      getAssasPix({ ...school, value }).then(data => {
        const id = data.id
        const qrCodePix = data.encodedImage
        const copyPastePix = data.payload
        if (qrCodePix && copyPastePix) {
          setPixData({qrCodePix, copyPastePix, value, id})
        }
      }).catch(() => {
        setAlertHtml('Não foi possível gerar o PIX.')
      })
    }
  }

    /** Verify credits Asaas */
    const verifyAsaasCreditHandler = (paymentId, value) => {
      setVerifyPaymentButton(true)
      asaasVerification({...school, student, transaction: paymentId}).then(({success}) => {
        if (success){
          const id = JSON.parse(localStorage.getItem(`SNACKNEWS_LOGIN:${getDomain()}`)).user
          addCredits({value, gateway: 'asaas', transaction: paymentId, status: 'paid', _student: id})
          .then(response => {
            if(response.success) {
              listCredits(student._id, 1).then(creditsData => {
                setCredits(creditsData)
                setLoading(false)
                setPaginationCredits({ pages: creditsData.pagination, active: creditsData.page })
              })
              loadStudentData()
              setPixData(null)
              setModalAsaasForm({ ...modalAsaasForm, show: false })
            } else {
              setAlertHtml('Erro ao adicionar créditos.')
            }
          }).finally(() => setVerifyPaymentButton(false))
        } else {
          setVerifyPaymentButton(false)
          setAlertHtml('O pagamento ainda não foi validado. Por favor, verifique novamente em alguns minutos.')
        }
      })
    }

  /** Logout */
  const logoutHandler = () => {
    if (logoutStudent()) props.history.push(`/${props.match.params.school}`)
  }

  return (<Container className={`${styles.bg} py-3 bg-light`} fluid>
    <Navbar className="p-0" color="light" light expand="md">
      <NavbarBrand className="text-uppercase font-weight-light">{school ? school.name : null}</NavbarBrand>
      <Nav navbar>
        <NavItem>{student ? student.name : null} ({student ? student.registration : null})</NavItem>
      </Nav>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Button className="rounded-0" color="danger" onClick={() => logoutHandler()}>Sair</Button>
        </NavItem>
      </Nav>
    </Navbar>
    <Row className="my-3 justify-content-center">
      <Col className="col-md-8 col-sm-12">
        <Card className="w-100 rounded-0">
          <CardHeader className="text-center border-bottom-0 text-uppercase">Compras</CardHeader>
          <CardBody className="p-0 data-container">
            <Loading show={loading} />
            <Table className={`mb-0 ${sells.data.length ? 'table-responsive' : null}`} size="sm">
              <thead>
                {!sells.data.length ?
                  <tr align="center">
                    <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhuma compra.</th>
                  </tr>
                  :
                  <tr align="center">
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Data</th>
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Código</th>
                    <th className="border-bottom-0 font-weight-normal text-uppercase">Itens</th>
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Total</th>
                  </tr>
                }
              </thead>
              <tbody>
                {sells.data.map(sell => (
                  <tr key={sell._id} align="center">
                    <td>{dateTime(sell._createdAt)}</td>
                    <td>{sell._id}</td>
                    <td>{
                      sell.itens.map((item, index) => {
                        const calc = sell.itens.length
                        return <span key={index}>{item.name} ({item.quantity}){(calc - 1 !== index) ? ', ' : null} </span>
                      })
                    }</td>
                    <td className="text-nowrap">{priceAjustFormat(sell.total)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <Pagination pages={paginationSells.pages} active={paginationSells.active} goPageHandler={goPageSellsHandler} />
          </CardFooter>
        </Card>
      </Col>
      <Col className="col-md-4 col-sm-12">
        <Card className="w-100 rounded-0">
          <CardHeader className="text-center border-bottom-0 text-uppercase">Créditos {student ? priceAjustFormat(student.credit) : null}</CardHeader>
          {school && school.emailPagSeguro && school.tokenPagSeguro ?
            <CardHeader className="border-bottom-0 p-0">
              <Button className="rounded-0 btn-sm text-uppercase" block color="primary" onClick={() => setModalPagSeguroForm({ ...modalPagSeguroForm, title: 'Adicionar créditos', show: true })} disabled>Adicionar creditos</Button>
            </CardHeader>
           : null}
           {school && school.pixKey && school.tokenAsaas ?
            <CardHeader className="border-bottom-0 p-0">
              <Button className="rounded-0 btn-sm text-uppercase" block color="primary" onClick={() => setModalAsaasForm({ ...modalAsaasForm, title: 'Adicionar créditos (Via PIX)', show: true })}>Adicionar creditos (Via PIX)</Button>
            </CardHeader>
           : null}
          <CardBody className="p-0 data-container">
            <Loading show={loading} />
            <Table className={`mb-0 ${credits.data.length ? 'table-responsive' : null}`} size="sm">
              <thead>
                {!credits.data.length ?
                  <tr align="center">
                    <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhum crédito foi adicionado para {credits.studentName}.</th>
                  </tr>
                  :
                  <tr align="center">
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Data</th>
                    <th className="border-bottom-0 font-weight-normal text-uppercase">Origem</th>
                    <th className="border-bottom-0 font-weight-normal text-uppercase">Status</th>
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Valor</th>
                  </tr>
                }
              </thead>
              <tbody>
                {credits.data.map(credit => (
                  <tr key={credit._id} align="center">
                    <td align="left" className="text-nowrap align-middle">{dateTime(credit._createdAt)}</td>
                    <td className="text-nowrap align-middle">{credit.gateway === 'asaas' ? 'PIX' : credit.gateway === 'pagseguro' ? 'Cartão de crédito' : credit.gateway === 'local' ? 'Estabelecimento' : 'Estorno de venda'}</td>
                    <td className="text-nowrap align-middle">{credit.status === 'paid' ? 'Confirmada' : null}{credit.status === 'pending' ? 'Pendente' : null}{credit.status === 'cancelled' ? 'Cancelada' : null}</td>
                    <td className="text-nowrap align-middle">{priceAjustFormat(credit.value)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <Pagination pages={paginationCredits.pages} active={paginationCredits.active} goPageHandler={goPageCreditsHandler} />
          </CardFooter>
        </Card>
      </Col>
    </Row>
    <ModalForm setModalForm={setModalPagSeguroForm} modalForm={modalPagSeguroForm} setAlertHtml={setAlertHtml} alertHtml={alertHtml} submit={addCreditsPagSeguroHandler}>
      <FormGroup className="mb-0" row>
        <Col className="col-12">
          <Input className="rounded-0" name="credits" type="text" placeholder="Valor*" />
        </Col>
        <Col className="col-12"><small>*Somente números ou decimal. Ex.: 10 ou 1,90</small></Col>
      </FormGroup>
    </ModalForm>
    <ModalForm setModalForm={setModalAsaasForm} modalForm={modalAsaasForm} setAlertHtml={setAlertHtml} alertHtml={alertHtml} submit={addCreditsAsaasHandler} hideSubmit={pixData ? true : false} hideCancel={pixData ? true : false}  >
      {pixData ? 
        <FormGroup className="mb-0" row>
          <Col className="col-12">
            <Alert color="warning" className="rounded-0"><strong>Atenção:</strong> Seu pagamento ainda não foi confirmado. Após realizar o pagamento com o seu banco, clique no botão abaixo para verificar o status da transação e adição dos créditos.</Alert> 
          </Col>
          <Col className="col-12">
            <Button className="rounded-0 w-100" color="primary" onClick={() => verifyAsaasCreditHandler(pixData.id, pixData.value)} disabled={verifyPaymentButton}>{verifyPaymentButton? 'Verificando Pagamento...': 'Verificar Pagamento'}</Button>
          </Col>
          <Col className="col-12 text-center">
            <img src={`data:image/png;base64,${pixData.qrCodePix}`} alt='QrCode PIX' />
          </Col>
          <Col className="col-12">
            <Input className="rounded-0" name="credits" type="textarea" rows={4}  value={pixData.copyPastePix} readOnly />
          </Col>
        </FormGroup> 
      : 
        <FormGroup className="mb-0" row>
          <Col className="col-12">
            <Input className="rounded-0" name="credits" type="text" placeholder="Valor*" disabled={pixData ? true : false} />
          </Col>
          <Col className="col-12"><small>*Somente números ou decimal. Ex.: 10 ou 1,90</small></Col>
      </FormGroup>
      }
    </ModalForm>
  </Container>)
}

export default Home