import api from '../api'

/** List users */
const listUsers = async (page) => {
  const users = page ? await api.get(`/user?sort=user&sortTo=1&page=${page}`) : await api.get('/user?sort=user&sortTo=1')
  return users.data
}

/** Add users */
const addUsers = async (data, id) => {
  const users = id ? await api.put(`/user/edit/${id}`, data) : await api.post('/user/add', data)
  return users.data
}

/** Delete users */
const deleteUsers = async (id) => {
  const users = await api.delete(`/user/${id}`)
  return users.data
}

export {
  listUsers,
  addUsers,
  deleteUsers
}