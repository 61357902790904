import Moment from "moment"
import api from '../api'

/** Get domain data */
const getDomain = () => {
  const fullPath = window.location.pathname
  const parts = fullPath.split('/')
  return parts[1]
}

/** Login user */
const loginUser = async (data) => {
  const now = new Date()
  const expire = Moment(now).add(10, 'hours')
  const login = await api.post('/login/user/', data)
  if (login.data.success) {
    localStorage.setItem(`SNACKNEWS_LOGIN_ADMIN:${getDomain()}`, JSON.stringify({
      ...login.data,
      expire
    }))
  }
  return login.data
}

/** Logout user */
const logoutUser = (area) => {
  const localData = JSON.parse(localStorage.getItem(`SNACKNEWS_LOGIN_ADMIN:${getDomain()}`))
  if (area === localData.access) {
    if (window.confirm('Deseja realmente sair do sistema?')) {
      localStorage.removeItem(`SNACKNEWS_LOGIN_ADMIN:${getDomain()}`)
      return true
    }
  }
  return false
}

/** Login student */
const loginStudent = async (data) => {
  const now = new Date()
  const expire = Moment(now).add(10, 'hours')
  const login = await api.post('/login/student/', data)
  if (login.data.success) {
    localStorage.setItem(`SNACKNEWS_LOGIN:${getDomain()}`, JSON.stringify({
      ...login.data,
      expire
    }))
  }
  return login.data
}

/** Logout student */
const logoutStudent = () => {
  localStorage.removeItem(`SNACKNEWS_LOGIN:${getDomain()}`)
  return true
}

/** Login settup */
const loginSettup = async (data) => {
  const now = new Date()
  const expire = Moment(now).add(10, 'hours')
  const login = await api.post('/login/', data)
  if (login.data.success) {
    localStorage.setItem('SNACKNEWS_LOGIN_SETTUP', JSON.stringify({
      ...login.data,
      expire
    }))
  }
  console.log(login.data)
  return login.data
}

/** Logout settup */
const logoutSettup = () => {
  localStorage.removeItem('SNACKNEWS_LOGIN_SETTUP')
  return true
}

export {
  loginUser,
  logoutUser,
  loginStudent,
  logoutStudent,
  loginSettup,
  logoutSettup
}