import api from '../api'

/** List categories */
const listCategories = async (page) => {
  const categories = page ? await api.get(`/category?sort=name&sortTo=1&page=${page}`) : await api.get('/category?sort=name&sortTo=1')
  return categories.data
}

/** Add categorie */
const addCategories = async (data, id) => {
  const categories = id ? await api.put(`/category/${id}`, data) : await api.post('/category', data)
  return categories.data
}

/** Add products */
const deleteCategories = async (id) => {
  const categories = await api.delete(`/category/${id}`)
  return categories.data
}

export {
  listCategories,
  addCategories,
  deleteCategories
}