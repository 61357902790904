import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
} from "reactstrap";
import styles from "./admin.css";

/** Components */
import Sells from "./sells";
import Products from "./products";
import Students from "./students";
import Configs from "./configs";

/** Controllers */
import { schoolData } from '../../controllers/schools'
import { logoutUser } from '../../controllers/login'
import { pagSeguroCheck } from '../../controllers/credits'

const Home = (props) => {
  /** On did mount */
  useEffect(() => {
    schoolData().then(schoolDataValue => {
      setSchool(schoolDataValue)
      pagSeguroCheck(schoolDataValue)
    })
  }, [])

  /** States */
  const [school, setSchool] = useState(null);

  /** Open PDV */
  const pdvHandler = () => {
    props.history.push(`/${props.match.params.school}/pdv`);
  };

  /** Open PDV */
  const pdvSeflHandler = () => {
    props.history.push(`/${props.match.params.school}/pdv/self`);
    const elem = document.getElementById("screen");
    if (window.confirm("Abrir em tela cheia?")) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  };

  /** Logout */
  const logoutHandler = () => {
    if (logoutUser("all"))
      props.history.push(`/${props.match.params.school}/admin/vendas`);
  };

  return (
    <Container id="screen" className={`${styles.bg} py-3 bg-light`} fluid>
      <Navbar className="p-0" color="light" light expand="md">
        <NavbarBrand className="text-uppercase font-weight-light">
          {school ? school.name : null}
        </NavbarBrand>
        <Nav navbar>
          <NavItem>
            <Link
              className="nav-link"
              to={`/${props.match.params.school}/admin/vendas`}
            >
              Vendas
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className="nav-link"
              to={`/${props.match.params.school}/admin/produtos`}
            >
              Produtos
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className="nav-link"
              to={`/${props.match.params.school}/admin/pessoas`}
            >
              Pessoas
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className="nav-link"
              to={`/${props.match.params.school}/admin/configs`}
            >
              Configurações
            </Link>
          </NavItem>
        </Nav>
        <NavbarToggler />
        <Nav className="ml-auto" navbar>
          <NavItem>
            <Button
              className="rounded-0"
              color="secondary"
              onClick={() => pdvSeflHandler()}
            >
              Autoatendimento
            </Button>
          </NavItem>
          <NavItem>
            <Button
              className="rounded-0"
              color="primary"
              onClick={() => pdvHandler()}
            >
              PDV
            </Button>
          </NavItem>
          <NavItem>
            <Button
              className="rounded-0"
              color="danger"
              onClick={() => logoutHandler()}
            >
              Sair
            </Button>
          </NavItem>
        </Nav>
      </Navbar>
      <Switch>
        <Redirect exact path="/:school/admin" to="/:school/admin/vendas" />
        <Route path="/:school/admin/pessoas" component={Students} />
        <Route path="/:school/admin/vendas" component={Sells} />
        <Route path="/:school/admin/produtos" component={Products} />
        <Route path="/:school/admin/configs" component={Configs} />
      </Switch>
    </Container>
  );
};

export default Home;
