import React from 'react'
import { Modal, Col, FormGroup, Input, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

import { totalCreditsDate } from '../../controllers/students'
import { dateTime } from '../../helpers/date'
import priceAjustFormat from '../../helpers/money'

const ModalTotalDate = (props) => {

  /** Show report */
  const showReport = (data) => {
    let itens = ''

    data.data.forEach(item => {
      itens += `
        <tr>
          <td>${dateTime(item._createdAt)}</td>
          <td align="center">${item.gateway === 'asaas' ? 'Asaas (PIX)' : item.gateway === 'pagseguro' ? 'Crédito via PagSeguro' : item.gateway === 'local' ? 'Crédito via estabelecimento' : 'Estorno de venda'}</td>
          <td align="center">${item.status === 'paid' ? 'Confirmado' : item.status === 'cancelled' ? 'Cancelado' : null}</td>
          <td align="right">${ priceAjustFormat(item.value)}</td>
        </tr>
      <tr>
        <td colspan="4" style=" border-top: dashed 1px black"></td>
      </tr>`
    })

    const html =
      `<button onClick="window.print();">Imprimir</button>
      <table width="100%" style="text-transform: uppercase; font-family: monospace">
        <tr><td colspan="4" align="center">Relatório de Créditos</td></tr>
        <tr><td colspan="4" align="center">${props.credits.studentName}</td></tr>
        <tr><td colspan="4" align="center">Período: ${ajustDate(window.document.getElementById('start').value)} a ${ajustDate(window.document.getElementById('end').value)}</td></tr>
        <tr>
          <td colspan="4" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
        ${itens}
        <tr>
        <td>Total no período</td>
        <td colspan="3" align="right">${priceAjustFormat(data.sumTotal)}</td>
        </tr>
        <tr>
          <td colspan="4" style="border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
      </table>`
    const mywindow = window.open('', 'PRINT', 'height=400,width=600')
    mywindow.document.write(html)
    mywindow.focus() // necessary for IE >= 10*/
  }

  const ajustDate = (date) => {
    const newDate = date.split('-')
    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`
  }

  const totalDate = async () => {
    const start = window.document.getElementById('start').value
    const end = window.document.getElementById('end').value
    if (start !== '' && end !== '') {
      const data = await totalCreditsDate(props.credits.student, start, end)
      showReport(data)
    }
  }

  return (
    <Modal isOpen={props.show} centered>
      <form>
        <ModalHeader><span className="text-uppercase font-weight-light">Relatório de créditos por período</span></ModalHeader>
        <ModalBody>
          <FormGroup className="mb-0" row>
            <Col >
              <Input id="start" className="rounded-0" type="date" placeholder="Inicial" />
            </Col>
            <Col >
              <Input id="end" className="rounded-0" type="date" placeholder="Final" />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="rounded-0 text-uppercase" color="primary" onClick={totalDate}>Gerar relatório</Button>
          <Button className="rounded-0 text-uppercase" color="secondary" onClick={() => props.setShow(false)}>Fechar</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default ModalTotalDate