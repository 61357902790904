import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

/** Components */
import { PrivateRouter, PrivateRouterAdmin, PrivateRouterSettup } from './routes-private'
import Message from './components/message/message'
import Home from './components/home/home'
//import Settup from './components/settup/settup'
import SettupLogin from './components/settup/login'
import SettupHome from './components/settup/home'
import StudentLogin from './components/student/login'
import StudentHome from './components/student/home'
import AdminLogin from './components/admin/login'
import AdminHome from './components/admin/home'
import Pdv from './components/pdv/pdv'
import PdvSelf from './components/pdv-self/pdv'

const Routes = () => {
  const fullPath = window.location.pathname
  const parts = fullPath.split('/')
  return (
    <Router>
      <Switch>
        {/*<Route path="/nova-escola" component={Settup} />*/}
        <Redirect exact path="/admin" to={'/admin/escolas'} />
        <Route exact path="/admin/login" component={SettupLogin} />
        <PrivateRouterSettup exact path="/admin/escolas" component={SettupHome} ifNotLogged={'/admin/login'} />
        <Route exact path="/" component={() => (<Home />)} />
        <Redirect exact path="/:school" to={`/${parts[1]}/pessoas`} />
        <Route path="/:school/pessoas/login" component={StudentLogin} />
        <Route path="/:school/admin/login" component={AdminLogin} />
        <PrivateRouter path="/:school/pessoas" component={StudentHome} ifNotLogged={`/${parts[1]}/pessoas/login`} />
        <PrivateRouterAdmin path="/:school/pdv/self" component={PdvSelf} ifNotLogged={`/${parts[1]}/admin/login`} />
        <PrivateRouterAdmin path="/:school/pdv" component={Pdv} ifNotLogged={`/${parts[1]}/admin/login`} />
        <PrivateRouterAdmin path="/:school/admin" component={AdminHome} ifNotLogged={`/${parts[1]}/admin/login`} />
        <Route component={() => (<Message message="A página solicitada não existe." />)} />
      </Switch>
    </Router>
  )
}

export default Routes