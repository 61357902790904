import api from '../api'

/** Select stuent */
const selectStudent = async (register) => {
  const student = await api.get(`pdv/student/${register}`) // Get student from database
  return student.data
}

/** Add product to cart */
const addProductToCart = (product, cart) => {
  const sum = cart.sum + product.price // Get sum total
  const item = cart.item // Get item data
  const onlyIdArray = item.map((id) => id.id) // Create an array with only id values
  const find = onlyIdArray.indexOf(product._id) // Find id product id exists in array
  if (find < 0) {
    /** Push new value */
    item.push({
      id: product._id,
      name: product.name,
      price: product.price,
      quantity: 1
    })
  } else {
    /** Update existing value */
    item[find] = {
      id: product._id,
      name: product.name,
      price: product.price,
      quantity: item[find].quantity + 1
    }
  }
  return {
    item,
    sum,
    pay: {
      credits: 0,
      money: 0
    }
  }
}

/** Play with money */
const payWithMoney = (event) => {
  let moneyValue = event.target.value || 0 // Recive value or set to zero 
  moneyValue = parseFloat(moneyValue).toFixed(2) // Ajust money value
  if (moneyValue > 0) event.target.value = moneyValue // Verify if value is more then zero to set value to input
  return parseFloat(moneyValue)
}

/** Pay with credits */
const payWithCredits = (event, studentCredit, cartTotal) => {
  let creditsValue = event.target.value || 0 // Recive value or set to zero
  creditsValue = creditsValue > studentCredit ? studentCredit : creditsValue // Credit value to use no more than avaliable
  creditsValue = creditsValue > cartTotal ? cartTotal : creditsValue // Credit value to use no more than total cart value
  creditsValue = parseFloat(creditsValue).toFixed(2) // Ajust credit value
  if (creditsValue > 0) event.target.value = creditsValue // Verify if value is more then zero to set value to input
  return parseFloat(creditsValue)
}

/** Pay with credits direct */
const payWithCreditsDirect = (studentCredit, cartTotal) => {
  return studentCredit > cartTotal ? cartTotal : studentCredit // Credit value to use no more than avaliable
}

/** Calculate change value */
const calculateChangeValue = (payMoneyValue, payCreditValue, cartTotal) => {
  let value = ((payMoneyValue + payCreditValue) - cartTotal) // Calculate change
  value = Math.max(0, value) // Only number above zero
  value = isNaN(value) ? 0 : value // Check if is not a  number
  return value
}

/** Calculate deficit value */
const calculateDeficitValue = (payMoneyValue, payCreditValue, cartTotal) => {
  let value = (cartTotal - (payMoneyValue + payCreditValue)) // Calculate defit
  value = Math.max(0, value) // Only number above zero
  value = isNaN(value) ? 0 : value // Check if is not a  number
  return value
}

/** Validate payment */
const validatePayment = async (cart, studentId, password, fastPassword) => {
  if (cart.pay.credits) {
    /** Verify password */
    const validate = await api.post(`pdv/student/${studentId}`, {
      fastPassword,
      password,
      credits: cart.pay.credits
    })
    if (!validate.data.valid) return false
  }
  if ((cart.pay.credits + cart.pay.money) < cart.sum) return false // Verify if payment is igual to sum
  const newDataToSave = {
    _student: studentId,
    payment: {
      ...cart.pay
    },
    itens: cart.item.map(item => ({
      ...item,
      _id: item.id
    })),
    total: cart.sum
  }
  const saveSell = await api.post('sell', newDataToSave) // Save sell
  return saveSell.data
}

export {
  calculateChangeValue,
  calculateDeficitValue,
  payWithMoney,
  payWithCredits,
  payWithCreditsDirect,
  addProductToCart,
  validatePayment,
  selectStudent
}