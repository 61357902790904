import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Alert, Button, Input, FormGroup } from 'reactstrap'
import styles from './login.css'

/** Controllers */
import { schoolData } from '../../controllers/schools'
import { loginUser } from '../../controllers/login'

const Login = (props) => {
  /** On did mount */
  useEffect(() => {
    schoolData().then(schoolDataValue => {
      if (schoolDataValue) {
        setStarting(false)
      } else {
        window.location.href = '/'
      }
      setSchool(schoolDataValue)
      document.title = `${schoolDataValue.name} - Acesso administrativo`
    })
  }, [])

  /** States */
  const [starting, setStarting] = useState(true)
  const [alertHtml, setAlertHtml] = useState(null)
  const [school, setSchool] = useState(null)
  const [loading, setLoading] = useState(false)

  /** Login handler  */
  const loginHandler = async () => {
    setLoading(true)
    const user = document.querySelector('#login-input').value
    const password = document.querySelector('#password-input').value
    if (!user || !password) {
      setAlertHtml('Os campos de login e senha são obrigatórios.')
      setLoading(false)
    } else {
      setAlertHtml(null)
      const login = await loginUser({ user, password })
      if (login.error) {
        setAlertHtml('Login ou senha inválidos, verifique os dados informados.')
        document.querySelector('#password-input').value = ''
        document.querySelector('#password-input').focus()
        setLoading(false)
      } else if (login.success) {
        console.log(props.history)
        if (login.access === 'pdv') window.location.href = `/${props.match.params.school}/pdv`
        if (login.access === 'all') window.location.href = `/${props.match.params.school}/admin`
      }
    }
  }

  return (<Container className={`${styles.bg} py-3 bg-light`} fluid>
    {starting ? <div className="starting"></div> : null}
    {loading ? <div className="starting" style={{opacity: 0.5}}></div> : null}
    <Row className="justify-content-center">
      <Col className="col-lg-3 col-md-4 col-sm-12">
        <Card className="w-100 rounded-0">
          <CardHeader className="text-center text-uppercase">{school ? school.name : null}</CardHeader>
          <CardBody>
            {school && school.image ? <div className="text-center">
              <img width="80%" src={`/static/media/${school.image}`} style={{padding: 10}} alt={school.name} />
            </div> : null}
            {alertHtml ? <Alert color="danger" className="rounded-0">{alertHtml}</Alert> : null}
            <FormGroup row>
              <Col className="col-12">
                <Input id="login-input" className="rounded-0 btn-block" type="text" placeholder="Login" />
              </Col>
            </FormGroup>
            <FormGroup className="mb-0" row>
              <Col className="col-12">
                <Input id="password-input" className="rounded-0 btn-block" type="password" placeholder="Senha" onKeyUp={(event) => { if (event.keyCode === 13) { loginHandler() } }} />
              </Col>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button className="rounded-0 text-uppercase btn-block" disabled={loading} color="primary" onClick={() => loginHandler()}>Entrar</Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </Container>)
}

export default Login