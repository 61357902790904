import api from '../api'

/** List products pdv */
const listProductsPdv = async (id) => {
  const products = await api.get(`pdv/products/${id}?sort=name&sortTo=1`)
  return products.data
}

/** List products */
const listProducts = async (id, page) => {
  const products = page ? await api.get(`products/${id}?sort=name&sortTo=1&page=${page}`) : await api.get(`products/${id}?sort=name&sortTo=1`)
  return products.data
}

/** Add products */
const addProducts = async (data, id) => {
  const products = id ? await api.put(`/product/${id}`, data) : await api.post('/product', data)
  return products.data
}

/** Add products */
const deleteProducts = async (id) => {
  const products = await api.delete(`/product/${id}`)
  return products.data
}

export {
  listProductsPdv,
  listProducts,
  addProducts,
  deleteProducts
}