import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Card, CardBody, CardHeader, CardFooter, Input, Button } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

/** Helpers */
import priceAjustFormat from '../../helpers/money'
import { dateTime } from '../../helpers/date'

/** Components */
import Pagination from './pagination'
import Loading from './loading'
import ModalTotalDate from './total-date'

/** Controllers */
import { schoolData } from '../../controllers/schools'
import { listSells, deleteSells } from '../../controllers/sells'

library.add([faTrash, faEdit]) // Use icons

const Sells = (props) => {
  /** On did mount */
  useEffect(() => {
    schoolData().then(schoolDataValue => {
      document.title = `${schoolDataValue.name} - Vendas`
      listSells(1, sellDate).then(sellsData => {
        setSells(sellsData)
        setLoading(false)
        setPagination({ pages: sellsData.pagination, active: 1 })
      })
    })
  }, [])

  /** Date now */
  const getDate = () => {
    const date = new Date()
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)
    return `${date.getFullYear()}-${month}-${day}`
  }

  /** States */
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({ pages: [1], active: 1 })
  const [sells, setSells] = useState({ data: [] })
  const [itens, setItens] = useState({ id: '', data: [] })
  const [sellDate, setSellDate] = useState(getDate())
  const [totalSellDate, setTotalSellDate] = useState(null)

  /** Go page pagination */
  const goPageHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listSells(page, sellDate).then(sellsData => {
      setSells(sellsData)
      setLoading(false)
      setPagination({ ...pagination, active: page })
    })
  }

  const newDateHandler = (date) => {
    let verify = date.split('-')
    verify = parseInt(verify[0])
    if (verify <= 9999 && verify >= 2000) {
      setLoading(true)
      setSellDate(date)
      listSells(1, date).then(sellsData => {
        setSells(sellsData)
        setLoading(false)
        setPagination({ pages: sellsData.pagination, active: 1 })
      })
    }
  }

  const showItensHandler = (id, itens) => {
    setItens({ id, data: itens })
  }

  const deleteSellsHander = (id) => {
    const response = window.prompt('Deseja realmente remover a venda? Esta ação não poderá ser desfeita.', 'Para confirmar digite SIM')
    if (response && response.toUpperCase() === 'SIM') {
      setLoading(true)
      deleteSells(id).then(() => {
        listSells(1, sellDate).then(sellsData => {
          setSells(sellsData)
          setLoading(false)
          setItens({ id: '', data: [] })
          setPagination({ pages: sellsData.pagination, active: 1 })
        })
      })
    } else {
      window.alert('Ação cancelada.')
    }
  }

  return (
    <React.Fragment>
      <Row className="my-3 justify-content-center">
        <Col className="col-md-8 col-sm-12">
          <Card className="w-100 rounded-0">
            <CardHeader className="text-center text-uppercase">Vendas<br />{sells.data.length ? `Total em vendas ${priceAjustFormat(sells.sumTotal)}.` : null}</CardHeader>
            <CardHeader className="border-bottom-0 p-0">
              <Input className="rounded-0 border-0" type="date" min="2000-01-01" defaultValue={sellDate} onChange={(event) => { newDateHandler(event.target.value) }} />
            </CardHeader>
            <CardBody className="p-0 data-container">
              <Loading show={loading} />
              <Table className={`mb-0 ${sells.data.length ? 'table-responsive' : null}`} size="sm" hover>
                <thead>
                  {!sells.data.length ?
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhuma venda.</th>
                    </tr>
                    :
                    <tr align="center">
                      <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Data</th>
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nome</th>
                      <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Total</th>
                      <th width="1%" className="border-bottom-0 font-weight-normal text-uppercase">Ações</th>
                    </tr>
                  }
                </thead>
                <tbody>
                  {sells.data.map(sell => (
                    <tr key={sell._id} align="center" className="pointer" onClick={() => showItensHandler(sell._id, sell.itens)}>
                      <td>{dateTime(sell._createdAt)}</td>
                      {sell._student ?
                        <td align="left" className="text-nowrap">{sell._student.registration} - {sell._student.name}</td>
                        :
                        <td align="left" className="text-nowrap">O registro foi excluído do sistema.</td>
                      }
                      <td className="text-nowrap">{priceAjustFormat(sell.total)}</td>
                      <td className="text-nowrap">
                        <Button className="rounded-0 btn-sm" block color="light" onClick={() => deleteSellsHander(sell._id)}><FontAwesomeIcon icon="trash" /></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter className="p-0">
              <Button className="rounded-0 text-uppercase" block color="secondary" onClick={() => setTotalSellDate(true)}>Relatório vendas por período</Button>
            </CardFooter>
            <CardFooter>
              <Pagination pages={pagination.pages} active={pagination.active} goPageHandler={goPageHandler} />
            </CardFooter>
          </Card>
        </Col>
        <Col className="col-md-4 col-sm-12">
          <Card className="w-100 rounded-0">
            <CardHeader className="text-center border-bottom-0 text-uppercase">Itens<br />{itens.id.length ? (<small>Venda: {itens.id}</small>) : null}</CardHeader>
            <CardBody className="p-0">
              <Table className={`mb-0 ${itens.data.length ? 'table-responsive' : null}`} size="sm">
                <thead>
                  {!itens.data.length ?
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhuma venda selecionada.<br />Selecione uma ao lado.</th>
                    </tr>
                    :
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Item</th>
                      <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Qnt.</th>
                      <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Preço</th>
                    </tr>
                  }
                </thead>
                <tbody>
                  {itens.data.map(item => (
                    <tr key={item._id} align="center">
                      <td align="left">{item.name}</td>
                      <td>{item.quantity}</td>
                      <td className="text-nowrap">{priceAjustFormat(item.price)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ModalTotalDate show={totalSellDate} setShow={setTotalSellDate}/>
    </React.Fragment >
  )
}

export default Sells