import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Card, CardBody, CardHeader, CardFooter, Button, Form, FormGroup, Input } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

/** Components */
import Pagination from './pagination'
import Loading from './loading'
import ModalForm from './form'

/** Controllers */
import { schoolData, addschool } from '../../controllers/schools'
import { listUsers, addUsers, deleteUsers } from '../../controllers/users'

library.add([faTrash, faEdit]) // Use icons

const configs = (props) => {
  /** On did mount */
  useEffect(() => {
    schoolData().then(schoolDataValue => {
      setSchool(schoolDataValue)
      document.title = `${schoolDataValue.name} - Produtos`
      listUsers(1).then(usersData => {
        setUsers(usersData)
        setLoading(false)
        setPagination({ pages: usersData.pagination, active: usersData.page })
      })
    })
  }, [])

  /** States */
  const [school, setSchool] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({ pages: [1], active: 1 })
  const [users, setUsers] = useState({ data: [] })
  const [modalForm, setModalForm] = useState({ show: false, title: '', form: '', data: null })
  const [alertHtml, setAlertHtml] = useState(null)

  /** Go page pagination users */
  const goPageUsersHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listUsers(page).then(usersData => {
      setUsers(usersData)
      setLoading(false)
      setPagination({ pages: usersData.pagination, active: usersData.page })
    })
  }

  const saveConfigsHander = (event) => {
    event.preventDefault()
    const id = school ? school._id : null
    const name = event.target.name.value
    const emailPagSeguro = event.target.emailPagSeguro.value
    const tokenPagSeguro = event.target.tokenPagSeguro.value
    const tokenAsaas = event.target.tokenAsaas.value
    const pixKey = event.target.pixKey.value
    addschool({ name, emailPagSeguro, tokenPagSeguro, tokenAsaas, pixKey }, id).then(schoolDataValue => {
      setSchool(schoolDataValue.data)
      document.title = `${schoolDataValue.data.name} - Produtos`
    })
  }

  /** Submit handler */
  const submitHandler = (event, form, data) => {
    event.preventDefault()
    const id = data ? data._id : null
    const user = event.target.user.value
    const access = event.target.access.value
    const password = event.target.password.value
    const confirm = event.target.confirm.value
    if (password !== confirm) {
      setAlertHtml('As senhas não conferem.')
      return false
    }
    setLoading(true)
    addUsers({ user, access, password }, id).then(response => {
      if (response.error) {
        setAlertHtml('Verifique os dados, todos os campos são obrigatórios.')
      } else {
        listUsers(1).then(usersData => {
          setUsers(usersData)
          setModalForm(false)
          setLoading(false)
          setPagination({ pages: usersData.pagination, active: usersData.page })
        })
      }
    })
  }

  /** Delete user */
  const deleteUsersHander = (id) => {
    if (window.confirm('Deseja realmente remover o usuário?')) {
      deleteUsers(id).then(() =>
        listUsers(1).then(usersData => {
          setUsers(usersData)
          setLoading(false)
          setPagination({ pages: usersData.pagination, active: usersData.page })
        })
      )
    }
  }

  return (
    <React.Fragment>
      <Row className="my-3 justify-content-center">
        <Col className="col-md-7 col-sm-12">
          <Card className="w-100 rounded-0">
            <Form onSubmit={(event) => saveConfigsHander(event)}>
              <CardHeader className="text-center text-uppercase">Configurações</CardHeader>
              <CardBody className="p-3 data-container">
                <FormGroup row>
                  <Col className="col-12">
                    <Input className="rounded-0" name="name" type="text" placeholder="Nome" defaultValue={school ? school.name : null} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col className="col-5">
                    <Input className="rounded-0" name="emailPagSeguro" required={false} type="email" placeholder="E-mail PagSeguro (opcional)" defaultValue={school ? school.emailPagSeguro : null} />
                  </Col>
                  <Col className="col-7">
                    <Input className="rounded-0" name="tokenPagSeguro" type="text" placeholder="Token PagSeguro (opcional)" defaultValue={school ? school.tokenPagSeguro : null} />
                  </Col>
                </FormGroup>
                <FormGroup className="mb-0" row>
                  <Col className="col-5">
                    <Input className="rounded-0" name="pixKey" required={false} type="text" placeholder="Chave Pix Asaas (opcional)" defaultValue={school ? school.pixKey : null} />
                  </Col>
                  <Col className="col-7">
                    <Input className="rounded-0" name="tokenAsaas" type="text" placeholder="Token Asaas (opcional)" defaultValue={school ? school.tokenAsaas : null} />
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooter>
                <Button className="rounded-0 btn-sm text-uppercase" disabled={loading ? true : false} block color="primary" type="submit" >Atualizar dados</Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
        <Col className="col-md-5 col-sm-12">
          <Card className="w-100 rounded-0">
            <CardHeader className="text-center text-uppercase">Usuários</CardHeader>
            <CardHeader className="border-bottom-0 p-0">
              <Button className="rounded-0 btn-sm text-uppercase" block color="primary" onClick={() => setModalForm({ show: true, title: 'Adicionar novo usuário', form: 'user', data: null })}>Adicionar novo usuário</Button>
            </CardHeader>
            <CardBody className="p-0 data-container">
              <Loading show={loading} />
              <Table className={`mb-0 ${users.data.length ? 'table-responsive' : null}`} size="sm">
                <thead>
                  {!users.data.length ?
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhum usuário.</th>
                    </tr>
                    :
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Login</th>
                      <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Permissão</th>
                      <th colSpan="2" width="5%" className="border-bottom-0 font-weight-normal text-uppercase" >Ações</th>
                    </tr>
                  }
                </thead>
                <tbody>
                  {users.data.map(user => (
                    <tr key={user._id} align="center">
                      <td className="text-nowrap align-middle">{user.user}</td>
                      <td className="text-nowrap align-middle">{user.access === 'all' ? 'Administrador' : 'Vendedor'}</td>
                      <td>
                        <Button className="rounded-0 btn-sm" block color="light" onClick={() => setModalForm({ show: true, title: 'Alterar usuário', form: 'user', data: user })}><FontAwesomeIcon icon="edit" /></Button>
                      </td>
                      <td>
                        <Button className="rounded-0 btn-sm" block color="light" disabled={user.user === 'admin' ? true : false} onClick={() => deleteUsersHander(user._id)}><FontAwesomeIcon icon="trash" /></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination pages={pagination.pages} active={pagination.active} goPageHandler={goPageUsersHandler} />
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <ModalForm setModalForm={setModalForm} modalForm={modalForm} setAlertHtml={setAlertHtml} alertHtml={alertHtml} submit={submitHandler} loading={loading}>
        <FormGroup row>
          <Col className="col-8">
            <Input className="rounded-0" name="user" readOnly={modalForm.data && modalForm.data.user === 'admin' ? true : false} type="text" placeholder="Login" defaultValue={modalForm.data ? modalForm.data.user : null} />
          </Col>
          <Col className="col-4">
            <Input className="rounded-0" name="access" type="select" placeholder="Login" defaultValue={modalForm.data ? modalForm.data.access : null}>
              <option value="all">Adaministrador</option>
              <option value="pdv">Vendedor</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col className="col-6">
            <Input className="rounded-0" name="password" type="password" placeholder="Senha" />
          </Col>
          <Col className="col-6">
            <Input className="rounded-0" name="confirm" type="password" placeholder="Confirmação da senha" />
          </Col>
        </FormGroup>
      </ModalForm>
    </React.Fragment>
  )
}

export default configs