/** Get current date and time */
const dateTimeNow = () => {
  const now = new Date() // Create new date
  const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate() // Ajust day to show
  let month = now.getMonth() + 1 // Ajust month number
  month = month < 10 ? `0${month}` : month // Ajust month to show
  const fullDate = `${day}/${month}/${now.getFullYear()}` // Full date
  return fullDate
}

/** Get date and time */
const dateTime = (inputDate) => {
  const date = new Date(inputDate) // Create new date
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate() // Ajust day to show
  let month = date.getMonth() + 1 // Ajust month number
  month = month < 10 ? `0${month}` : month // Ajust month to show
  const fullDate = `${day}/${month}/${date.getFullYear()}` // Full date
  return fullDate
}

export {
  dateTimeNow,
  dateTime
}