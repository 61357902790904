import React from 'react'
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Col, FormGroup, Input, Button } from 'reactstrap'

const PdvValidate = (props) => (
  <Modal isOpen={props.modalPassword} onOpened={() => { document.querySelector('#password-input').focus() }} centered>
    <ModalHeader><span className="text-uppercase font-weight-light mb-0">Passe o cartão ou solicite a senha</span></ModalHeader>
    <ModalBody className="pb-0">
      {props.processing ? <Alert color="warning" className="rounded-0">Processando...</Alert> :
        (<FormGroup row>
          <Col>
            <Input id="password-input" className="rounded-0 btn-block" type="password" placeholder="Senha de autorização" onKeyUp={(event) => { if (event.keyCode === 13) { props.paySellHandler(true) } }} />
          </Col>
        </FormGroup>)
      }
    </ModalBody>
    {props.processing ? null :
      <ModalFooter>
        <Button className="rounded-0 text-uppercase" color="success" onClick={() => props.paySellHandler(true)}>Autorizar</Button>
        <Button className="rounded-0 text-uppercase" color="danger" onClick={() => props.setModalPassword(false)}>Cancelar</Button>
      </ModalFooter>
    }
  </Modal>
)

export default PdvValidate