import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Card, CardBody, CardHeader, CardFooter, Button, FormGroup, Input } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

/** Helpers */
import priceAjustFormat from '../../helpers/money'

/** Components */
import Pagination from './pagination'
import Loading from './loading'
import ModalForm from './form'

/** Controllers */
import { schoolData } from '../../controllers/schools'
import { listCategories, addCategories, deleteCategories } from '../../controllers/categories'
import { listProducts, addProducts, deleteProducts } from '../../controllers/products'

library.add([faTrash, faEdit]) // Use icons

const Products = (props) => {
  /** On did mount */
  useEffect(() => {
    schoolData().then(schoolDataValue => {
      document.title = `${schoolDataValue.name} - Produtos`
      listCategories(1).then(categoriesData => {
        setCategories(categoriesData)
        setLoading(false)
        setPagination({ categories: { pages: categoriesData.pagination, active: categoriesData.page }, products: { pages: [1], active: 1 } })
      })
    })
  }, [])

  /** States */
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({ categories: { pages: [1], active: 1 }, products: { pages: [1], active: 1 } })
  const [categories, setCategories] = useState({ data: [] })
  const [products, setProducts] = useState({ category: null, categoryName: '', products: { data: [] } })
  const [modalForm, setModalForm] = useState({ show: false, title: '', form: '', data: null })
  const [alertHtml, setAlertHtml] = useState(null)

  /** Go page pagination categories */
  const goPageCategoriesHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listCategories(page).then(categoriesData => {
      setCategories(categoriesData)
      setLoading(false)
      setPagination({ categories: { pages: categoriesData.pagination, active: categoriesData.page }, products: { pages: [], active: 1 } })
    })
  }

  /** Go page pagination products */
  const goPageProductsHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listProducts(products.category, page).then(productsData => {
      setProducts({ ...products, products: productsData })
      setLoading(false)
      setPagination({ ...pagination, products: { pages: productsData.pagination, active: productsData.page } })
    })
  }


  const showProductsHandler = (categoryName, categoryId) => {
    listProducts(categoryId, 1).then(productsData => {
      setProducts({ category: categoryId, categoryName, products: productsData })
      setLoading(false)
      setPagination({ ...pagination, products: { pages: productsData.pagination, active: productsData.page } })
    })
  }

  const submitHandler = (event, form, data) => {
    event.preventDefault()
    const id = data ? data._id : null
    if (form === 'category') {
      const name = event.target.name.value
      setLoading(true)
      addCategories({ name }, id).then(response => {
        if (response.error) {
          setAlertHtml('Verifique os dados, todos os campos são obrigatórios.')
        } else {
          listCategories(1).then(categoriesData => {
            setCategories(categoriesData)
            setModalForm(false)
            setLoading(false)
            setPagination({ categories: { pages: categoriesData.pagination, active: categoriesData.page }, products: { pages: [], active: 1 } })
          })
        }
      })
    } else if (form === 'product') {
      const name = event.target.name.value
      const price = event.target.price.value.replace(',', '.')
      addProducts({ name, price, _category: products.category }, id).then(response => {
        if (response.error) {
          setAlertHtml('Verifique os dados, todos os campos são obrigatórios.')
        } else {
          listProducts(products.category, 1).then(productsData => {
            setProducts({ ...products, products: productsData })
            setModalForm(false)
            setLoading(false)
            setPagination({ ...pagination, products: { pages: productsData.pagination, active: productsData.page } })
          })
        }
      })
    }
  }

  const deleteCategorieHander = (id) => {
    if (window.confirm('Deseja realmente remover a categoria?')) {
      deleteCategories(id).then(() =>
        listCategories(1).then(categoriesData => {
          setCategories(categoriesData)
          setLoading(false)
          setProducts({ category: null, categoryName: '', products: { data: [] } })
          setPagination({ categories: { pages: categoriesData.pagination, active: categoriesData.page }, products: { pages: [1], active: 1 } })
        })
      )
    }
  }

  const deleteProductsHander = (id) => {
    if (window.confirm('Deseja realmente remover o produto?')) {
      deleteProducts(id).then(() =>
        listProducts(products.category, 1).then(productsData => {
          setProducts({ ...products, products: productsData })
          setLoading(false)
          setPagination({ ...pagination, products: { pages: productsData.pagination, active: productsData.page } })
        })
      )
    }
  }

  return (
    <React.Fragment>
      <Row className="my-3 justify-content-center">
        <Col className="col-md-4 col-sm-12">
          <Card className="w-100 rounded-0">
            <CardHeader className="text-center text-uppercase">Categorias</CardHeader>
            <CardHeader className="border-bottom-0 p-0">
              <Button className="rounded-0 btn-sm text-uppercase" block color="primary" onClick={() => setModalForm({ show: true, title: 'Adicionar nova categoria', form: 'category', data: null })}>Adicionar nova categoria</Button>
            </CardHeader>
            <CardBody className="p-0 data-container">
              <Loading show={loading} />
              <Table className={`mb-0 ${categories.data.length ? 'table-responsive' : null}`} size="sm" hover>
                <thead>
                  {!categories.data.length ?
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhuma categoria.</th>
                    </tr>
                    :
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nome</th>
                      <th colSpan="2" width="5%" className="border-bottom-0 font-weight-normal text-uppercase" >Ações</th>
                    </tr>
                  }
                </thead>
                <tbody>
                  {categories.data.map(category => (
                    <tr key={category._id} align="center" className="pointer" onClick={() => showProductsHandler(category.name, category._id)}>
                      <td className="text-nowrap align-middle">{category.name}</td>
                      <td>
                        <Button className="rounded-0 btn-sm" block color="light" onClick={() => setModalForm({ show: true, title: 'Alterar categoria', form: 'category', data: category })}><FontAwesomeIcon icon="edit" /></Button>
                      </td>
                      <td>
                        <Button className="rounded-0 btn-sm" block color="light" onClick={() => deleteCategorieHander(category._id)}><FontAwesomeIcon icon="trash" /></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination pages={pagination.categories.pages} active={pagination.categories.active} goPageHandler={goPageCategoriesHandler} />
            </CardFooter>
          </Card>
        </Col>
        <Col className="col-md-8 col-sm-12">
          <Card className="w-100 rounded-0">
            <CardHeader className="text-center border-bottom-0 text-uppercase">Produtos<br />{products.categoryName.length ? (<small>Categoria: {products.categoryName}</small>) : null}</CardHeader>
            {products.category ?
              <CardHeader className="border-bottom-0 p-0">
                <Button className="rounded-0 btn-sm text-uppercase" block color="primary" onClick={() => setModalForm({ show: true, title: 'Adicionar novo produto', form: 'product', data: null })}>Adicionar novo produto</Button>
              </CardHeader>
              : null}
            <CardBody className="p-0 data-container">
              <Loading show={loading} />
              <Table className={`mb-0 ${products.category ? 'table-responsive' : null}`} size="sm">
                <thead>
                  {!products.category ?
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhuma categoria selecionada.<br />Selecione uma ao lado.</th>
                    </tr>
                    :
                    !products.products.data.length ?
                      <tr align="center">
                        <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhum produto na categoria {products.categoryName}.</th>
                      </tr>
                      :
                      <tr align="center">
                        <th className="border-bottom-0 font-weight-normal text-uppercase">Nome</th>
                        <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Preço</th>
                        <th colSpan="2" width="5%" className="border-bottom-0 font-weight-normal text-uppercase" >Ações</th>
                      </tr>
                  }
                </thead>
                <tbody>
                  {products.products.data.map(product => (
                    <tr key={product._id} align="center">
                      <td align="left" className="text-nowrap align-middle">{product.name}</td>
                      <td className="text-nowrap align-middle">{priceAjustFormat(product.price)}</td>
                      <td>
                        <Button className="rounded-0 btn-sm" block color="light" onClick={() => setModalForm({ show: true, title: 'Alterar produto', form: 'product', data: product })}><FontAwesomeIcon icon="edit" /></Button>
                      </td>
                      <td>
                        <Button className="rounded-0 btn-sm" block color="light" onClick={() => deleteProductsHander(product._id)}><FontAwesomeIcon icon="trash" /></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination pages={pagination.products.pages} active={pagination.products.active} goPageHandler={goPageProductsHandler} />
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <ModalForm setModalForm={setModalForm} modalForm={modalForm} setAlertHtml={setAlertHtml} alertHtml={alertHtml} submit={submitHandler} loading={loading}>
        {modalForm.form === 'category' ? (
          <FormGroup className="mb-0" row>
            <Col>
              <Input className="rounded-0" name="name" type="text" placeholder="Nome" defaultValue={modalForm.data ? modalForm.data.name : null} />
            </Col>
          </FormGroup>
        ) : null}
        {modalForm.form === 'product' ? (
          <FormGroup className="mb-0" row>
            <Col className="col-8">
              <Input className="rounded-0" name="name" type="text" placeholder="Nome" defaultValue={modalForm.data ? modalForm.data.name : null} />
            </Col>
            <Col className="col-4">
              <Input className="rounded-0" name="price" type="text" placeholder="Preço*" defaultValue={modalForm.data ? modalForm.data.price.toFixed(2) : null} />
            </Col>
            <Col className="col-12"><small>*Somente números ou decimal. Ex.: 10 ou 1,90</small></Col>
          </FormGroup>
        ) : null}
      </ModalForm>
    </React.Fragment>
  )
}

export default Products