import React, { useState, useEffect } from 'react'
import { Container, Navbar, NavbarBrand, Nav, NavItem, Button, Row, Col, Table, Card, CardBody, CardHeader, CardFooter, FormGroup, Input } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit, faImage } from '@fortawesome/free-solid-svg-icons'
import styles from './schools.css'

/** Components */
import Pagination from './pagination'
import Loading from './loading'
import ModalForm from './form'

/** Controllers */
import { listSchool, addschool, deleteSchools } from '../../controllers/schools'
import { logoutSettup } from '../../controllers/login'

library.add([faTrash, faEdit, faImage]) // Use icons

const Home = (props) => {
  /** On did mount */
  useEffect(() => {
    listSchool(1).then(schoolsData => {
      setSchools(schoolsData)
      setLoading(false)
      setPagination({ pages: schoolsData.pagination, active: schoolsData.page })
    })
  }, [])

  /** States */
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({ pages: [1], active: 1 })
  const [schools, setSchools] = useState({ data: [] })
  const [modalForm, setModalForm] = useState({ show: false, title: '', form: '', data: null })
  const [alertHtml, setAlertHtml] = useState(null)

  /** Go page pagination */
  const goPageHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listSchool(page).then(schoolsData => {
      setSchools(schoolsData)
      setLoading(false)
      setPagination({ pages: schoolsData.pagination, active: schoolsData.page })
    })
  }

  /** Add school */
  const submitHandler = (event, data) => {
    event.preventDefault()
    const id = data ? data._id : null
    const domain = data ? data.domain : event.target.domain.value
    const name = event.target.name.value
    const emailPagSeguro = event.target.emailPagSeguro.value
    const tokenPagSeguro = event.target.tokenPagSeguro.value
    const tokenAsaas = event.target.tokenAsaas.value
    const pixKey = event.target.pixKey.value
    const password = event.target.password.value
    const confirm = event.target.confirm.value
    const image = event.target.image.files[0]
    const dataSave = new FormData()
    dataSave.append('name', name)
    dataSave.append('emailPagSeguro', emailPagSeguro)
    dataSave.append('tokenPagSeguro', tokenPagSeguro)
    dataSave.append('tokenAsaas', tokenAsaas)
    dataSave.append('pixKey', pixKey)
    dataSave.append('domain', domain)
    dataSave.append('password', password)
    dataSave.append('image', image)
    if (!data && (domain === '' || name === '' || password === '' || confirm === '')) {
      setAlertHtml('Todos os campos obrigatórios devem ser preenchidos.')
      return false
    }
    const pattern = new RegExp('[^a-z0-9\-]+')
    if (domain && pattern.test(domain)) {
      setAlertHtml('O nome do diretório deve ser em minússculo e não pode conter espaços ou caracteres especiais. Caso necessite separa palavras utilize o hífen(-).')
      return false
    }
    if (password !== confirm) {
      setAlertHtml('A senha e a confirmação de senha devem ser iaguais.')
      return false
    }
    setAlertHtml(false)
    setLoading(true)
    addschool(dataSave, id).then(response => {
      if (response.error) {
        setAlertHtml('Verifique os dados e todos os campos obrigatórios. O diretório é único para cada escola e não pode ser repetido.')
        setLoading(false)
      } else {
        listSchool(1).then(schoolsData => {
          setSchools(schoolsData)
          setModalForm(false)
          setLoading(false)
          setPagination({ pages: schoolsData.pagination, active: schoolsData.page })
        })
      }
    })
  }

  const deleteSchoolsHander = (id) => {
    if (window.confirm('Deseja realmente remover a escola?')) {
      deleteSchools(id).then(() =>
        listSchool(1).then(schoolsData => {
          setSchools(schoolsData)
          setLoading(false)
          setPagination({ pages: schoolsData.pagination, active: schoolsData.page })
        })
      )
    }
  }

  /** Logout */
  const logoutHandler = () => {
    if (logoutSettup()) props.history.push(`/admin`)
  }

  return (<Container className={`${styles.bg} py-3 bg-light`} fluid>
    <Navbar className="p-0" color="light" light expand="md">
      <NavbarBrand className="text-uppercase font-weight-light">Gestão do sistema</NavbarBrand>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Button className="rounded-0" color="danger" onClick={() => logoutHandler()}>Sair</Button>
        </NavItem>
      </Nav>
    </Navbar>
    <Row className="my-3 justify-content-center">
      <Col className="col-12">
        <Card className="w-100 rounded-0">
          <CardHeader className="text-center border-bottom-0 text-uppercase">Escolas</CardHeader>
          <CardHeader className="border-bottom-0 p-0">
            <Button className="rounded-0 btn-sm text-uppercase" block color="primary" onClick={() => setModalForm({ show: true, title: 'Adicionar nova escola', form: 'school', data: null })}>Adicionar nova escola</Button>
          </CardHeader>
          <CardBody className="p-0 data-container">
            <Loading show={loading} />
            <Table className={`mb-0 ${schools.data.length ? 'table-responsive' : null}`} size="sm">
              <thead>
                {!schools.data.length ?
                  <tr align="center">
                    <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhuma escola cadastrada.</th>
                  </tr>
                  :
                  <tr align="center">
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Logo</th>
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Diretório</th>
                    <th className="border-bottom-0 font-weight-normal text-uppercase">Nome</th>
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">PagSeguro</th>
                    <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Asaas</th>
                    <th colSpan="2" width="5%" className="border-bottom-0 font-weight-normal text-uppercase" >Ações</th>
                  </tr>
                }
              </thead>
              <tbody>
                {schools.data.map(school => (
                  <tr key={school._id} align="center">
                    <td>
                      <Button className="rounded-0 btn-sm" block color="light" disabled={school.image ? false : true} onClick={() => setModalForm({ show: true, title: 'Logo', form: 'picture', data: school })}><FontAwesomeIcon icon="image" /></Button>
                    </td>
                    <td>/{school.domain}</td>
                    <td align="left">{school.name}</td>
                    <td>{school.emailPagSeguro && school.tokenPagSeguro ? 'Ativado' : 'Desativado'}</td>
                    <td>{school.pixKey && school.tokenAsaas ? 'Ativado' : 'Desativado'}</td>
                    <td>
                      <Button className="rounded-0 btn-sm" block color="light" onClick={() => setModalForm({ show: true, title: 'Alterar escola', form: 'school', data: school })}><FontAwesomeIcon icon="edit" /></Button>
                    </td>
                    <td>
                      <Button className="rounded-0 btn-sm" block color="light" onClick={() => deleteSchoolsHander(school._id)}><FontAwesomeIcon icon="trash" /></Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <Pagination pages={pagination.pages} active={pagination.active} goPageHandler={goPageHandler} />
          </CardFooter>
        </Card>
      </Col>
    </Row>
    <ModalForm showButton={modalForm.form === 'school' ? true : false} setModalForm={setModalForm} modalForm={modalForm} setAlertHtml={setAlertHtml} alertHtml={alertHtml} submit={submitHandler} loading={loading}>
      {modalForm.form === 'school' ? <div>
        <FormGroup row>
          <Col className="col-4">
            <Input className="rounded-0" name="domain" type="text" placeholder="Diretório" disabled={modalForm.data ? true : false} defaultValue={modalForm.data ? modalForm.data.domain : null} />
          </Col>
          <Col className="col-8">
            <Input className="rounded-0" name="name" type="text" placeholder="Nome" defaultValue={modalForm.data ? modalForm.data.name : null} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col className="col-6">
            <Input className="rounded-0" name="emailPagSeguro" required={false} type="email" placeholder="E-mail PagSeguro (opcional)" defaultValue={modalForm.data ? modalForm.data.emailPagSeguro : null} />
          </Col>
          <Col className="col-6">
            <Input className="rounded-0" name="tokenPagSeguro" type="text" placeholder="Token PagSeguro (opcional)" defaultValue={modalForm.data ? modalForm.data.tokenPagSeguro : null} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col className="col-5">
            <Input className="rounded-0" name="pixKey" required={false} type="text" placeholder="Chave Pix Asaas (opcional)" defaultValue={modalForm.data ? modalForm.data.pixKey : null} />
          </Col>
          <Col className="col-7">
            <Input className="rounded-0" name="tokenAsaas" type="text" placeholder="Token Asaas (opcional)" defaultValue={modalForm.data ? modalForm.data.tokenAsaas : null} />
          </Col>
        </FormGroup>
        <FormGroup className="mb-0" row>
          <Col className="col-5">
            <Input className="rounded-0" name="password" type="password" placeholder="Senha admin" />
          </Col>
          <Col className="col-5">
            <Input className="rounded-0" name="confirm" type="password" placeholder="Confirmação da senha" />
          </Col>
          <Col className="col-2">
            <label htmlFor="file-upload" className="pointer custom-file-upload rounded-0 btn-block text-uppercase btn btn-primary">
              <FontAwesomeIcon icon="image" />
            </label>
            <input id="file-upload" name="image" type="file" accept="image/*" style={{ display: 'none' }} />
          </Col>
        </FormGroup>
      </div> : null}
      {modalForm.form === 'picture' ? <div className="text-center">
        <img width="100%" height="100%" src={`/static/media/${modalForm.data.image}`} alt="logo" />
      </div> : null}
    </ModalForm>
  </Container>)
}

export default Home