import React from 'react'
import { Col, Card, CardBody } from 'reactstrap'
import styles from './pdv.css'

const CartItem = (props) => (
  <Col className="col p-2" onClick={props.action}>
    <Card className="w-100 rounded-0 text-nowrap pointer">
      <CardBody>
        <h5 className={` ${styles.item} font-weight-light text-uppercase text-center m-0`}>{props.name}<br /><small className="font-weight-bold">{props.price}</small></h5>
      </CardBody>
    </Card>
  </Col>
)

export default CartItem