import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Card, CardBody, CardHeader, CardFooter, Button, FormGroup, Input } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'

/** Helpers */
import priceAjustFormat from '../../helpers/money'
import { dateTime } from '../../helpers/date'

/** Components */
import Pagination from './pagination'
import Loading from './loading'
import ModalForm from './form'
import ModalTotalDate from './total-date-credits'

/** Controllers */
import { schoolData } from '../../controllers/schools'
import { listStudents, addStudents, deleteStudents } from '../../controllers/students'
import { listCredits, pagSeguroStatus, addCredits } from '../../controllers/credits'

library.add([faTrash, faEdit, faSearch]) // Use icons

const credits = (props) => {
  /** On did mount */
  useEffect(() => {
    schoolData().then(schoolDataValue => {
      setSchool(schoolDataValue)
      document.title = `${schoolDataValue.name} - Produtos`
      listStudents(1, search).then(studentsData => {
        setStudents(studentsData)
        setLoading(false)
        setPagination({ students: { pages: studentsData.pagination, active: studentsData.page }, credits: { pages: [1], active: 1 } })
      })
    })
  }, [])

  /** States */
  const [school, setSchool] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({ students: { pages: [1], active: 1 }, credits: { pages: [1], active: 1 } })
  const [students, setStudents] = useState({ data: [] })
  const [credits, setCredits] = useState({ student: null, studentName: '', studentCredit: null, credits: { data: [] } })
  const [modalForm, setModalForm] = useState({ show: false, title: '', form: '', data: null })
  const [alertHtml, setAlertHtml] = useState(null)
  const [search, setSearch] = useState(null)
  const [totalCreditDate, setTotalCreditDate] = useState(null)

  /** Go page pagination students */
  const goPageStudentsHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listStudents(page, search).then(studentsData => {
      setStudents(studentsData)
      setLoading(false)
      setPagination({ ...pagination, students: { pages: studentsData.pagination, active: studentsData.page } })
    })
  }

  /** Go page pagination credits */
  const goPageCreditsHandler = (event, page) => {
    event.preventDefault()
    setLoading(true)
    listCredits(credits.student, page).then(creditsData => {
      setCredits({ ...credits, credits: creditsData })
      setLoading(false)
      setPagination({ ...pagination, credits: { pages: creditsData.pagination, active: creditsData.page } })
    })
  }

  /** Show credits */
  const showCreditsHandler = (studentName, studentId, studentCredit) => {
    listCredits(studentId, 1).then(creditsData => {
      setCredits({ student: studentId, studentName, studentCredit, credits: creditsData })
      setLoading(false)
      setPagination({ ...pagination, credits: { pages: creditsData.pagination, active: creditsData.page } })
    })
  }

  /** Submit handler */
  const submitHandler = (event, form, data) => {
    event.preventDefault()
    const id = data ? data._id : null
    const name = event.target.name.value
    //const birth = event.target.birth.value
    const registration = event.target.registration.value
    const password = event.target.password.value
    const confirm = event.target.confirm.value
    if (password !== confirm) {
      setAlertHtml('A senha e a confirmação devem ser iguais.')
      return false
    }
    setLoading(true)
    addStudents({ name, registration, password }, id).then(response => {
      if (response.error) {
        setAlertHtml('Verifique os dados, todos os campos são obrigatórios.')
      } else {
        listStudents(1, search).then(studentsData => {
          setStudents(studentsData)
          setModalForm(false)
          setLoading(false)
          setPagination({ ...pagination, students: { pages: studentsData.pagination, active: studentsData.page } })
        })
      }
    })
  }

  /** Delete student */
  const deleteStudentsHander = (id) => {
    const response = window.prompt('Deseja realmente remover a pessoa? Esta ação não poderá ser desfeita.', 'Para confirmar digite SIM')
    if (response && response.toUpperCase() === 'SIM') {
      setLoading(true)
      deleteStudents(id).then(() =>
        listStudents(1, search).then(studentsData => {
          setStudents(studentsData)
          setLoading(false)
          setPagination({ ...pagination, students: { pages: studentsData.pagination, active: studentsData.page } })
        })
      )
    } else {
      window.alert('Ação cancelada.')
    }
  }

  /** Insert credits */
  const insertCredits = (event, form, data) => {
    event.preventDefault()
    const value = parseFloat(event.target.credits.value.replace(',', '.'))
    setLoading(true)
    addCredits({ value, gateway: 'local', transaction: null, status: 'paid', _student: data.id })
      .then(response => {
        if (response.success) {
          const sum = (parseFloat(data.credit) + value)
          addStudents({ credit: sum }, data.id).then(() => {
            listStudents(1, search).then(studentsData => {
              setStudents(studentsData)
              setModalForm(false)
              setLoading(false)
              setPagination({ ...pagination, students: { pages: studentsData.pagination, active: studentsData.page } })
            })
            listCredits(data.id, 1).then(creditsData => {
              setCredits({ ...credits, studentCredit: sum, credits: creditsData })
              setLoading(false)
              setPagination({ ...pagination, credits: { pages: creditsData.pagination, active: creditsData.page } })
            })
            setModalForm({ ...modalForm, show: false })
          })
        } else {
          setAlertHtml('Erro ao adicionar créditos.')
        }
      })
  }

  /** Confirm payment */
  const confimPaymentHandler = (transaction, addCredit) => {
    setLoading(true)
    pagSeguroStatus({ ...school, transaction, student: credits.student, addCredit }).then(() => {
      setLoading(true)
      listStudents(pagination.students.active, search).then(studentsData => {
        setStudents(studentsData)
        setPagination({ ...pagination, students: { pages: studentsData.pagination, active: studentsData.page } })
      })
      listCredits(credits.student, pagination.credits.active).then(creditsData => {
        setCredits({ ...credits, credits: creditsData })
        setLoading(false)
        setPagination({ ...pagination, credits: { pages: creditsData.pagination, active: creditsData.page } })
      })
    })
  }

  /** Search handler */
  const searchHandler = () => {
    setLoading(true)
    listStudents(1, search).then(studentsData => {
      setStudents(studentsData)
      setLoading(false)
      setPagination({ ...pagination, students: { pages: studentsData.pagination, active: studentsData.page } })
    })
  }

  return (
    <React.Fragment>
      <Row className="my-3 justify-content-center">
        <Col className="col-md-8 col-sm-12">
          <Card className="w-100 rounded-0">
            <CardHeader className="text-center text-uppercase">Pessoas</CardHeader>
            <CardHeader className="border-bottom-0 p-0">
              <FormGroup className="mb-0" row>
                <Col className="col-11 pr-0">
                  <Input className="rounded-0 border-0" name="credits" type="text" placeholder="Busca por nome ou matrícula" onKeyUp={(event) => { if (event.keyCode === 13) { searchHandler() } else setSearch(event.target.value) }} />
                </Col>
                <Col className="col pl-0">
                  <Button className="rounded-0 btn" block color="secondary" onClick={() => searchHandler()} ><FontAwesomeIcon icon="search" /></Button>
                </Col>
              </FormGroup>
            </CardHeader>
            <CardHeader className="border-bottom-0 p-0">
              <Button className="rounded-0 btn-sm text-uppercase" block color="primary" onClick={() => setModalForm({ show: true, title: 'Adicionar nova pessoa', form: 'student', data: null })}>Adicionar nova pessoa</Button>
            </CardHeader>
            <CardBody className="p-0 data-container">
              <Loading show={loading} />
              <Table className={`mb-0 ${students.data.length ? 'table-responsive' : null}`} size="sm" hover>
                <thead>
                  {!students.data.length ?
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhuma pessoa.</th>
                    </tr>
                    :
                    <tr align="center">
                      <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Matrícula</th>
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nome</th>
                      <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Créditos</th>
                      <th colSpan="2" width="5%" className="border-bottom-0 font-weight-normal text-uppercase" >Ações</th>
                    </tr>
                  }
                </thead>
                <tbody>
                  {students.data.map(student => (
                    <tr key={student._id} align="center" className="pointer" onClick={() => showCreditsHandler(student.name, student._id, student.credit)}>
                      <td className="text-nowrap align-middle">{student.registration}</td>
                      <td className="text-nowrap align-middle">{student.name}</td>
                      <td className="text-nowrap align-middle">{priceAjustFormat(student.credit)}</td>
                      <td>
                        <Button className="rounded-0 btn-sm" block color="light" onClick={() => setModalForm({ show: true, title: 'Alterar pessoa', form: 'student', data: student })}><FontAwesomeIcon icon="edit" /></Button>
                      </td>
                      <td>
                        <Button className="rounded-0 btn-sm" block color="light" onClick={() => deleteStudentsHander(student._id)}><FontAwesomeIcon icon="trash" /></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <Pagination pages={pagination.students.pages} active={pagination.students.active} goPageHandler={goPageStudentsHandler} />
            </CardFooter>
          </Card>
        </Col>
        <Col className="col-md-4 col-sm-12">
          <Card className="w-100 rounded-0">
            <CardHeader className="text-center border-bottom-0 text-uppercase">Movimentações<br />{credits.studentName.length ? (<small>Pessoa: {credits.studentName}</small>) : null}</CardHeader>
            {credits.student ?
              <CardHeader className="border-bottom-0 p-0">
                <Button className="rounded-0 btn-sm text-uppercase" block color="primary" onClick={() => setModalForm({ show: true, title: 'Adicionar créditos', form: 'credit', data: { credit: credits.studentCredit, id: credits.student } })}>Adicionar creditos</Button>
              </CardHeader>
              : null}
            <CardBody className="p-0 data-container">
              <Loading show={loading} />
              <Table className={`mb-0 ${credits.student ? 'table-responsive' : null}`} size="sm">
                <thead>
                  {!credits.student ?
                    <tr align="center">
                      <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhuma pessoa selecionado.<br />Selecione uma ao lado.</th>
                    </tr>
                    :
                    !credits.credits.data.length ?
                      <tr align="center">
                        <th className="border-bottom-0 font-weight-normal text-uppercase">Nenhum crédito foi adicionado para a pessoa {credits.studentName}.</th>
                      </tr>
                      :
                      <tr align="center">
                        <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Data</th>
                        <th className="border-bottom-0 font-weight-normal text-uppercase">Origem</th>
                        <th className="border-bottom-0 font-weight-normal text-uppercase">Status</th>
                        <th width="5%" className="border-bottom-0 font-weight-normal text-uppercase">Valor</th>
                      </tr>
                  }
                </thead>
                <tbody>
                  {credits.credits.data.map(credit => (
                    <tr key={credit._id} align="center">
                      <td align="left" className="text-nowrap align-middle">{dateTime(credit._createdAt)}</td>
                      <td className="text-nowrap align-middle">{credit.gateway === 'asaas' ? 'Asaas (PIX)' : credit.gateway === 'pagseguro' ? 'PagSeguro' : credit.gateway === 'local' ? 'Estabelecimento' : 'Estorno de venda'}</td>
                      <td className="text-nowrap align-middle">{credit.status === 'paid' ? 'Confirmado' : null}{credit.status === 'cancelled' ? 'Cancelado' : null}{credit.status === 'pending' ? <Button className="rounded-0 btn-sm text-uppercase" color="primary" onClick={() => confimPaymentHandler(credit.transaction, credit.value)} >Confirmar</Button> : null}</td>
                      <td className="text-nowrap align-middle">{priceAjustFormat(credit.value)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            {credits.student ? (
              <CardFooter className="p-0">
                <Button className="rounded-0 text-uppercase" block color="secondary" onClick={() => setTotalCreditDate(true)}>Relatório creditos por período</Button>
              </CardFooter>
            ) : null}
            <CardFooter>
              <Pagination pages={pagination.credits.pages} active={pagination.credits.active} goPageHandler={goPageCreditsHandler} />
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <ModalForm setModalForm={setModalForm} modalForm={modalForm} setAlertHtml={setAlertHtml} alertHtml={alertHtml} submit={modalForm.form === 'student' ? submitHandler : insertCredits} loading={loading}>
        {modalForm.form === 'student' ? (<React.Fragment>
          <FormGroup row>
            <Col className="col-12">
              <Input className="rounded-0" name="name" type="text" placeholder="Nome" defaultValue={modalForm.data ? modalForm.data.name : null} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col className="col-12">
              <Input className="rounded-0" name="registration" type="text" placeholder="Matrícula" defaultValue={modalForm.data ? modalForm.data.registration : null} />
            </Col>
            {/*
            <Col className="col-5">
              <Input className="rounded-0" name="birth" type="date" placeholder="Data de nascimento" defaultValue={modalForm.data ? modalForm.data.birth : null} />
            </Col>
            */}
          </FormGroup>
          <FormGroup className="mb-0" row>
            <Col className="col-6">
              <Input className="rounded-0" name="password" type="password" placeholder="Senha" />
            </Col>
            <Col className="col-6">
              <Input className="rounded-0" name="confirm" type="password" placeholder="Confirmação da senha" />
            </Col>
          </FormGroup>
        </React.Fragment>) : null}
        {modalForm.form === 'credit' ? (<React.Fragment>
          <FormGroup className="mb-0" row>
            <Col className="col-12">
              <Input className="rounded-0" name="credits" type="text" placeholder="Valor*" />
            </Col>
            <Col className="col-12"><small>*Somente números ou decimal. Ex.: 10 ou 1,90</small></Col>
          </FormGroup>
        </React.Fragment>) : null}
      </ModalForm>
      <ModalTotalDate credits={credits} show={totalCreditDate} setShow={setTotalCreditDate}/>
    </React.Fragment>
  )
}

export default credits