import React from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import styles from './home.css'
import ad from '../../assets/home.jpeg'

const Message = (props) => {

  return (<Container className={`${styles.bg} py-3 bg-light`} fluid>
    <Row className="justify-content-center">
      <Col className="col-md-6 col-sm-12">
        <Card className="w-100 rounded-0">
          <img class="img-fluid" src={ad} alt="Snack News" />
        </Card>
      </Col>
    </Row>
  </Container>)
}

export default Message