import api from '../api'

/** List credits */
const listCredits = async (id, page) => {
  const credits = page ? await api.get(`/credit/${id}?sort=_createdAt&sortTo=-1&page=${page}`) : await api.get(`/credit/${id}?sort=_createdAt&sortTo=-1`)
  return credits.data
}

/** Add credits */
const addCredits = async (data) => {
  const credits = await api.post('/credit', data)
  return credits.data
}

/** Pagseguro token */
const getPagSeguroToken = async (data) => {
  const pagseguro = await api.post(`/pagseguro`, data)
  return pagseguro.data
}

/** Pagseguro status */
const pagSeguroStatus = async (data) => {
  const pagseguro = await api.post(`/pagseguro/verify`, data)
  return pagseguro.data
}

/** Pagseguro status */
const pagSeguroCheck = async (data) => {
  const pagseguro = await api.post(`/pagseguro/check`, data)
  return pagseguro.data
}

/** Asaas pix */
const getAssasPix = async (data) => {
  const asaas = await api.post(`/asaas`, data)
  return asaas.data
}

/** Asaas status */
const asaasCheck = async (data) => {
  const pagseguro = await api.post(`/asaas/check`, data)
  return pagseguro.data
}

/** Asaas status */
const asaasVerification = async (data) => {
  const pagseguro = await api.post(`/asaas/verify`, data)
  return pagseguro.data
}

export {
  listCredits,
  addCredits,
  getPagSeguroToken,
  pagSeguroStatus,
  pagSeguroCheck,
  getAssasPix,
  asaasVerification,
  asaasCheck
}