import React from 'react'
import { Modal, Col, FormGroup, Input, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

import { totalSellsDate } from '../../controllers/sells'
import { dateTime } from '../../helpers/date'
import priceAjustFormat from '../../helpers/money'

const ModalTotalDate = (props) => {

  /** Show report */
  const showReport = (data) => {
    let itens = ''
    let credits = 0

    data.data.forEach(item => {
      let subitens = ''

      item.itens.forEach(item => {
        subitens += `
          <tr>
            <td width="40%">${item.name}</td>
            <td width="20%" align="center">${item.quantity}</td>
            <td width="40%" align="right">${priceAjustFormat(item.price)}</td>
          </tr>`
      })

      itens += `
        <tr>
          <td colspan="2">${item._student ? `Nome: ${item._student.name}` : 'Registro excluído do sistema'}</td>
          <td align="right">${item._student ? `#${item._student.registration}` : null}</td>
        </tr>
        <tr>
          <td colspan="2">Data: ${dateTime(item._createdAt)}</td>
          <td align="right">Total: ${ priceAjustFormat(item.total)}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black;"></td>
        </tr>
        <tr>
        <td>Item:</td>
        <td align="center">Qnt.:</td>
        <td align="right">Preço:</td>
      </tr>
      ${subitens}
      <tr>
        <td colspan="3" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
      </tr>`
    })

    data.data.forEach(item => {
      credits = credits + item.payment.credits
    })

    const html =
      `<button onClick="window.print();">Imprimir</button>
      <table width="100%" style="text-transform: uppercase; font-family: monospace">
        <tr><td colspan="3" align="center">Relatório de vendas</td></tr>
        <tr><td colspan="3" align="center">Período: ${ajustDate(window.document.getElementById('start').value)} a ${ajustDate(window.document.getElementById('end').value)}</td></tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
        ${itens}
        <tr>
        <td>Total no período</td>
        <td colspan="2" align="right">${priceAjustFormat(data.sumTotal)}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black; border-bottom: dashed 1px black;"></td>
        </tr>
        <tr>
          <td>Pago em credito:</td>
          <td colspan="2" align="right">${priceAjustFormat(credits)}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black;"></td>
        </tr>
        <tr>
          <td>Pago em dinheiro:</td>
          <td colspan="2" align="right">${priceAjustFormat((data.sumTotal - credits))}</td>
        </tr>
        <tr>
          <td colspan="3" style=" border-top: dashed 1px black;"></td>
        </tr>
      </table>`
    const mywindow = window.open('', 'PRINT', 'height=400,width=600')
    mywindow.document.write(html)
    mywindow.focus() // necessary for IE >= 10*/
  }

  const ajustDate = (date) => {
    const newDate = date.split('-')
    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`
  }

  const totalDate = async () => {
    const start = window.document.getElementById('start').value
    const end = window.document.getElementById('end').value
    if (start !== '' && end !== '') {
      const data = await totalSellsDate(start, end)
      showReport(data)
    }
  }

  return (
    <Modal isOpen={props.show} centered>
      <form>
        <ModalHeader><span className="text-uppercase font-weight-light">Relatório de vendas por período</span></ModalHeader>
        <ModalBody>
          <FormGroup className="mb-0" row>
            <Col >
              <Input id="start" className="rounded-0" type="date" placeholder="Inicial" />
            </Col>
            <Col >
              <Input id="end" className="rounded-0" type="date" placeholder="Final" />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="rounded-0 text-uppercase" color="primary" onClick={totalDate}>Gerar relatório</Button>
          <Button className="rounded-0 text-uppercase" color="secondary" onClick={() => props.setShow(false)}>Fechar</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default ModalTotalDate