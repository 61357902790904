import api from '../api'

/** List sells */
const listSells = async (page, date) => {
  const sells = await api.get(`/sell?sort=_createdAt&sortTo=-1&page=${page}&date=${date}`)
  return sells.data
}

/** Total sells per date */
const totalSellsDate = async (start, end) => {
  const sells = await api.get(`/sell-date?dateStart=${start}&dateEnd=${end}`)
  return sells.data
}

/** List sells student */
const listSellsStudent = async (id, page) => {
  const sells = page ? await api.get(`/sell/${id}?sort=_createdAt&sortTo=-1&page=${page}`) : await api.get(`/sell/${id}?sort=_createdAt&sortTo=-1`)
  return sells.data
}

/** Add products */
const deleteSells = async (id) => {
  const sells = await api.delete(`/sell/${id}`)
  return sells.data
}

export {
  listSells,
  totalSellsDate,
  listSellsStudent,
  deleteSells
}