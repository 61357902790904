import React from 'react'

const Pagination = (props) => {
  return (<nav>
    <ul className="pagination mb-0 justify-content-center pagination-sm" style={{flexWrap: 'wrap'}}>
      {props.pages.map(page => <li key={page} className={`page-item my-1 ${page === props.active ? 'active' : null}`}><a className="page-link rounded-0 " href="" onClick={(event) => props.goPageHandler(event, page)}>{page}</a></li>)}
    </ul>
  </nav>)
}

export default Pagination