import React from 'react'
import { Route, Redirect } from "react-router-dom"
import Moment from "moment"

/** Get domain data */
const getDomain = () => {
  const fullPath = window.location.pathname
  const parts = fullPath.split('/')
  return parts[1]
}

/** Verify if login is expired */
const expired = (dateToExpire) => {
  const now = new Date()
  return Moment(now).isBefore(dateToExpire)
}

/** Verify if is logged */
const isLogged = () => {
  const localData = JSON.parse(localStorage.getItem(`SNACKNEWS_LOGIN:${getDomain()}`))
  if (localData && localData.token.length && localData.user.length && expired(localData.expire)) return true
  return false
}

const isLoggedAdmin = () => {
  const localData = JSON.parse(localStorage.getItem(`SNACKNEWS_LOGIN_ADMIN:${getDomain()}`))
  if (localData && localData.token.length && localData.user.length && expired(localData.expire)) return true
  return false
}

const isLoggedSettup = () => {
  const localData = JSON.parse(localStorage.getItem('SNACKNEWS_LOGIN_SETTUP'))
  if (localData && localData.token.length && expired(localData.expire)) return true
  return false
}

/** Private route login check */
const PrivateRouter = (props) =>
  isLogged() ? (<Route {...props}>{props.children}</Route>) : <Redirect to={props.ifNotLogged || '/'} />

const PrivateRouterAdmin = (props) => 
  isLoggedAdmin() ? (<Route {...props}>{props.children}</Route>) : <Redirect to={props.ifNotLogged || '/'} />

const PrivateRouterSettup = (props) => 
  isLoggedSettup() ? (<Route {...props}>{props.children}</Route>) : <Redirect to={props.ifNotLogged || '/'} />

export { PrivateRouter, PrivateRouterAdmin, PrivateRouterSettup }