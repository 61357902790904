import axios from 'axios'
import configs from './configs.json'

/** Get token */
const getToken = () => {
  if (localStorage.getItem(`SNACKNEWS_LOGIN_ADMIN:${getDomain()}`)) {
    let localData = localStorage.getItem(`SNACKNEWS_LOGIN_ADMIN:${getDomain()}`) // Local storage login admin
    localData = JSON.parse(localData)
    return localData.token
  } else if (localStorage.getItem(`SNACKNEWS_LOGIN:${getDomain()}`)) {
    let localData = localStorage.getItem(`SNACKNEWS_LOGIN:${getDomain()}`) // Local storage login
    localData = JSON.parse(localData)
    return localData.token
  } else if (localStorage.getItem('SNACKNEWS_LOGIN_SETTUP')) {
    let localData = localStorage.getItem('SNACKNEWS_LOGIN_SETTUP') // Local storage login settup
    localData = JSON.parse(localData)
    return localData.token
  } else {
    return null
  }
}

/** Get domain data */
const getDomain = () => {
  const fullPath = window.location.pathname
  const parts = fullPath.split('/')
  return parts[1]
}

/** Api data */
const api = axios.create({
  baseURL: configs.ApiUrl,
  headers: {
    'domain': getDomain(),
    'token': getToken()
  }
})

export default api