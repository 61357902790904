import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, FormGroup, Input, Button, Label } from 'reactstrap'

const PdvPayment = (props) => (
  <Modal isOpen={props.modalPayment} centered toggle={() => props.finishSellHandler()}>
      <ModalHeader toggle={() => props.finishSellHandler()}><span className="text-uppercase font-weight-light">{`Total a pagar: ${props.priceAjustFormat(props.cart.sum)}`}</span></ModalHeader>
      <ModalBody>
        <p className="text-center">{props.student ? `O total de créditos disponíveis para ${props.student.name} é de ${props.priceAjustFormat(props.student.credit)}.` : null}</p>
        <FormGroup row>
          <Col className="col-auto">
            <Label>Valor em créditos</Label>
          </Col>
          <Col>
            <Input className="rounded-0" type="number" min="0.00" placeholder="0.00" onBlur={(event) => props.payWithCreditsHandler(event)} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col className="col-auto">
            <Label>Valor em dinheiro</Label>
          </Col>
          <Col>
            <Input className="rounded-0" type="number" min="0.00" placeholder="0.00" onBlur={(event) => props.payWithMoneyHandler(event)} />
          </Col>
        </FormGroup>
        <FormGroup className="m-0" row>
          <Col className="col text-center">
            <Label>{`Falta: ${props.priceAjustFormat(props.calculateDeficitValue(props.cart.pay.money, props.cart.pay.credits, props.cart.sum))}`}</Label>
          </Col>
          <Col className="col text-center">
            <Label>{`Troco: ${props.priceAjustFormat(props.calculateChangeValue(props.cart.pay.money, props.cart.pay.credits, props.cart.sum))}`}</Label>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button id="pay-button" className="rounded-0 text-uppercase" color="success" disabled={props.calculateDeficitValue(props.cart.pay.money, props.cart.pay.credits, props.cart.sum) ? true : false} onClick={props.cart.pay.credits ? () => props.setModalPassword(true) : () => props.paySellHandler()}>Pagar</Button>
        <Button className="rounded-0 text-uppercase" color="warning" onClick={() => props.finishSellHandler()}>Cancelar</Button>
      </ModalFooter>
    </Modal>
)

export default PdvPayment